
<header class="fm-dialog-header">
  <p mat-dialog-title></p>
  <button (click)="close()" class="dialog__close"><svg class="svg-i-close" viewBox="0 0 512 512" id="i-close"><path d="M274.329 255.503l135.146-135.146-19.316-19.317-135.145 135.146L119.869 101.04l-19.317 19.317 135.146 135.146-135.146 135.145 19.317 19.316 135.145-135.146zm19.297 19.286l115.83 115.83-19.304 19.306L274.32 294.094z"></path></svg></button>
</header>
<div mat-dialog-content class="dialog-image-series dialog__message--active">
  <app-image-series [product]="product"></app-image-series>
</div>
<div mat-dialog-actions>
</div>
