import { Component, OnInit } from '@angular/core';
import { DataStore } from "./data.store";
import { slideInAnimation } from './route-animation';
import { AnimationHelper } from './animation.helper';
import { TranslocoService } from '@ngneat/transloco';
import { AppBaseComponent } from './app.base.component';

@Component({
  selector: 'app-root-internal',
  templateUrl: './app.component.html',
  animations: [slideInAnimation]
})
export class InternalAppComponent extends AppBaseComponent implements OnInit {
  constructor(dataStore: DataStore,
    animationHelper: AnimationHelper,
    translocoService: TranslocoService) {
    super(dataStore, animationHelper, translocoService, false);
    this.isAuthenticated = true;
  }

  ngOnInit(): void {
    this.loadLanguage();
    this.setTouchAndBrowser();
    this.dataStore.initializeUserContext();
  }

  loginRedirect() {
  }

  logout() {
  }
}

