import { Component } from '@angular/core';
import { Observable } from "rxjs";
import { DataStore } from "../data.store";
import { LoggingService } from '../logging/logging.service';
import { TranslatedProduct } from '../model/translated-product';

@Component({
  selector: 'sea',
  templateUrl: './sea.component.html',
})
export class SeaComponent {
  products: Observable<Array<TranslatedProduct>>;
  seaHeader: string;
  seaText: string;

  constructor(private dataStore: DataStore, private loggingService: LoggingService) {
    this.products = this.dataStore.seaProducts;

    this.dataStore.seaPageHeader.subscribe(res => {
      this.seaHeader = res;
    }, error => console.error(error));

    this.dataStore.seaPageText.subscribe(res => {
      this.seaText = res;
    }, error => console.error(error));
  }
}
