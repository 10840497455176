import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslatedProduct } from '../model/translated-product';
@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-details-dialog.component.html',
})
export class ProductDetailsDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: TranslatedProduct, private router: Router, public dialogRef: MatDialogRef<ProductDetailsDialogComponent>) { }
  product: TranslatedProduct | null;
  closeRoute: string | undefined;

  ngOnInit() {
    let index = this.router.url.lastIndexOf("/");
    this.closeRoute = this.router.url.substring(0, index);
    this.product = this.data;
  }

  close() {
    this.dialogRef.close(null);
  }
}
