<div class="main-module-container" style="padding-right:2rem;">
  <h3 class="divider headline--secondary">{{ contactHeader }}</h3>
  <p style="padding-bottom:1rem;">
    {{ contactText }}
  </p>

  <div class="container">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="m-form -no-padding m-form--max-width">
      <mat-form-field class="row">
        <input matInput formControlName="name" autocomplete="off" class="m-input" [class.has-value]="name.value.length">
        <svg class="icon svg-i-checkmark" viewBox="0 0 19 14" id="i-checkmark"><path d="M5.854 13.854a.5.5 0 000-.707l-5-5a.5.5 0 00-.707.707l5 5a.498.498 0 00.707 0zm2.5-2.5l10.5-10.5a.5.5 0 00-.707-.707l-10.5 10.5a.5.5 0 00.707.707z"></path></svg>
        <label>{{ "contact.name" | transloco }} *</label>
        <mat-error *ngIf="!name.valid && name.touched">
          Kontaktfel
        </mat-error>
      </mat-form-field>
      <mat-form-field class="row">
        <input matInput formControlName="unit" autocomplete="off" class="m-input" [class.has-value]="unit.value.length">
        <svg class="icon svg-i-checkmark" viewBox="0 0 19 14" id="i-checkmark"><path d="M5.854 13.854a.5.5 0 000-.707l-5-5a.5.5 0 00-.707.707l5 5a.498.498 0 00.707 0zm2.5-2.5l10.5-10.5a.5.5 0 00-.707-.707l-10.5 10.5a.5.5 0 00.707.707z"></path></svg>
        <label>{{ "contact.unit" | transloco }} *</label>
        <mat-error *ngIf="!unit.valid && unit.touched">
          Förbandsfel
        </mat-error>
      </mat-form-field>
      <mat-form-field class="row">
        <input matInput formControlName="phone" autocomplete="off" class="m-input" [class.has-value]="phone.value.length">
        <svg class="icon svg-i-checkmark" viewBox="0 0 19 14" id="i-checkmark"><path d="M5.854 13.854a.5.5 0 000-.707l-5-5a.5.5 0 00-.707.707l5 5a.498.498 0 00.707 0zm2.5-2.5l10.5-10.5a.5.5 0 00-.707-.707l-10.5 10.5a.5.5 0 00.707.707z"></path></svg>
        <label>{{ "contact.phone" | transloco }} *</label>
        <mat-error *ngIf="!phone.valid && phone.touched">
          Telefonfel
        </mat-error>
      </mat-form-field>
      <mat-form-field class="row">
        <textarea matInput formControlName="matter" autocomplete="off" class="m-input" [class.has-value]="matter.value.length"></textarea>
        <svg class="icon svg-i-checkmark" viewBox="0 0 19 14" id="i-checkmark"><path d="M5.854 13.854a.5.5 0 000-.707l-5-5a.5.5 0 00-.707.707l5 5a.498.498 0 00.707 0zm2.5-2.5l10.5-10.5a.5.5 0 00-.707-.707l-10.5 10.5a.5.5 0 00.707.707z"></path></svg>
        <label>{{ "contact.matter" | transloco }} *</label>
        <mat-error *ngIf="!matter.valid && matter.touched">
          Ärendefel
        </mat-error>
      </mat-form-field>
      <button type="submit" [disabled]="!formGroup.valid" class="c-button -small -ghost  -green -ghost--thin -ghost--light">
        <span>{{ "contact.createButtonText" | transloco }}</span>
      </button>
    </form>
  </div>
  <div *ngIf="(isInternalDeployment === 'true')">
    <h3 class="divider headline--secondary">{{ contactMissingHeader }}</h3>
    <div class="m-form -no-padding m-form--no-flex">
      <p>{{ contactMissingText }}</p>
      <p>
        <a href={{contactLinkLink}} target="_blank">{{ contactLinkText }}</a>
      </p>
    </div>
  </div>
</div>
