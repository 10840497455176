<div class="img-container">
  <div class="image-and-controls">
    <img class="image-slider-display" [src]="selectedImageContent" />
    <div>
      <p><strong>{{ timeStamps[value] }}</strong></p>
      <div class="slider slider--label-on-top slider--untouched stagger-out image-series-slider">
        <div class="slider__element">
          <mat-slider class="slider__new"
                      [disabled]="disabled"
                      [step]="step"
                      [max]="max"
                      [min]="min"
                      [(ngModel)]="value"
                      (input)="updateLabel($event)">
          </mat-slider>
        </div>
      </div>
      <div style="display:flex">
        <button [disabled]="value <= min" class="c-button -small -ghost  -green -ghost--thin -ghost--light" (click)="decrementCounter()"> {{"media.previousButton" | transloco}} </button>
        <button [disabled]="value >= max" class="c-button -small -ghost  -green -ghost--thin -ghost--light" (click)="incrementCounter()" style="margin-left:1rem;">{{"media.nextButton" | transloco}} </button>
        <button [disabled]="play === 1" class="c-button -small -ghost  -green -ghost--thin -ghost--light" (click)="start()" style="margin-left:1rem;">{{"media.playButton" | transloco}}</button>
        <button class="c-button -small -ghost  -green -ghost--thin -ghost--light" (click)="stop()" style="margin-left:1rem;">{{"media.stopButton" | transloco}}</button>
        <p style='font-family: "ForsvarsmaktenSans", "Akkurat", Arial, sans-serif; margin-left: 1em; line-height: 2em; '>{{"media.speedButton" | transloco}}</p>
        <select [disabled]="play === 1" class="m-select-image-series" style="margin-left: 1em; margin-top: 5px; width:3em" #selectedSpeed (change)="setSpeed(selectedSpeed.value)">
          <option value=1000> 0.5</option>
          <option selected value=500>1</option>
          <option value=250>2</option>
        </select>
      </div>
    </div>
  </div>
  <ul class="timestamp-list">
    <li *ngFor="let timeStamp of timeStamps; let i = index" (mouseenter)="onMouseEnter(i)" style="cursor:pointer;" [ngClass]="{'selected': timeStamp === selectedTimeStamp}">
      {{ timeStamp }}
    </li>
  </ul>
</div>
