import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { UserContext } from './model/userContext';

@Injectable({
  providedIn: 'root',
})
export class DataService
  extends BaseService {
  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string) {
    super(http, baseUrl);
  }

  getUserContext(): Observable<UserContext> {
    const url = this.baseUrl + 'api/main/GetUserContext';
    return this.http.get<UserContext>(url, { withCredentials: true });
  }

  createFavourite(productId: number): Observable<number> {
    const url = this.baseUrl + 'api/main/CreateFavourite';
    let parameters = new HttpParams();
    parameters = parameters.append('productId', productId);
    return this.http.get<number>(url, { params: parameters, withCredentials: true });
  }

  deleteFavourite(productId: number): Observable<boolean> {
    const url = this.baseUrl + 'api/main/DeleteFavourite';
    let parameters = new HttpParams();
    parameters = parameters.append('productId', productId);
    return this.http.get<boolean>(url, { params: parameters, withCredentials: true });
  }

  favouriteClicked(productId: number): Observable<boolean> {
    const url = this.baseUrl + 'api/main/FavouriteClicked';
    let parameters = new HttpParams();
    parameters = parameters.append('productId', productId);
    return this.http.get<boolean>(url, { params: parameters, withCredentials: true });
  }

  productClicked(productId: number): Observable<boolean> {
    const url = this.baseUrl + 'api/main/ProductClicked';
    let parameters = new HttpParams();
    parameters = parameters.append('productId', productId);
    return this.http.get<boolean>(url, { params: parameters, withCredentials: true });
  }

  logException(exception: Error) {
    const url = this.baseUrl + 'api/main/LogException';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const json = JSON.stringify(this.getExceptionAsString(exception));

    return this.http.post<boolean>(url, json, { headers: headers, withCredentials: true });
  }

  getExceptionAsString(exception: Error): string {
    return exception.name + ": " + exception.message + " Stack: " + exception.stack;
  }

  getFile(filePath: string, productId: number) {
    const url = this.baseUrl + 'api/main/GetFile';
    let parameters = new HttpParams();
    parameters = parameters.append('file', filePath).append('productId', productId);
    return this.http.get(url, { params: parameters, withCredentials: true, responseType: 'blob' });
  }

  getJsonData(filePath: string, productId: number) {
    const url = this.baseUrl + 'api/main/GetJsonData';
    let parameters = new HttpParams();
    parameters = parameters.append('file', filePath).append('productId', productId);
    return this.http.get(url, { params: parameters, withCredentials: true });
  }

  getImage(filePath: string, productId: number) {
    const url = this.baseUrl + 'api/main/GetImage';
    let parameters = new HttpParams();
    parameters = parameters.append('file', filePath).append('productId', productId);
    return this.http.get(url, { params: parameters, withCredentials: true });
  }
}
