import { Component, Input, OnChanges } from '@angular/core';
import { TranslatedProduct } from '../model/translated-product';
import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import Exporting from 'highcharts/modules/exporting';
import Windbarb from 'highcharts/modules/windbarb';
import { DataService } from '../data.service';
Exporting(Highcharts);
Windbarb(Highcharts);

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  standalone: true,
  imports: [HighchartsChartModule]
})

export class GraphComponent implements OnChanges {

  constructor(private dataService: DataService) {
  }
  @Input() reRender: boolean;
  @Input() product: TranslatedProduct;
  public highcharts = Highcharts;
  updating = false;
  chart1Data: any;
  chart2Data: any;
  highchart1: Highcharts.Chart | undefined;
  highchart2: Highcharts.Chart | undefined;
  imageContainer: Highcharts.SVGElement | undefined;

  ngOnChanges() {
    this.dataService.getJsonData('graph_1_data.json', this.product.id)
      .subscribe(data => {
        this.chart1Data = data;

        //Set events before initiate chart
        this.chart1Data.xAxis[0].events.afterSetExtremes = () => { this.setExtremes2(); };
        if (this.product.productTypeId === 300) {
          this.chart1Data.chart.events.render = () => { this.drawWeatherSymbols(); };
        }

        this.highchart1 = this.highcharts.chart('highchart1Container', this.chart1Data);

        //Initial drawing of symbols
        if (this.product.productTypeId === 300) {
          this.drawWeatherSymbols();
        }
      });

    this.dataService.getJsonData('graph_2_data.json', this.product.id)
      .subscribe(data => {
        this.chart2Data = data;
        this.chart2Data.xAxis[0].events.afterSetExtremes = () => { this.setExtremes1() };
        this.highchart2 = this.highcharts.chart('highchart2Container', this.chart2Data);
      });
  }

  ngOnInit() {
  }

  setExtremes1() {
    let extremes = this.highchart2?.xAxis[0].getExtremes();
    if (!this.updating && extremes != null) {
      this.updating = true;
      this.highchart1?.xAxis[0].setExtremes(extremes.min, extremes.max);
    } else {
      this.updating = false;
    }
  }

  setExtremes2() {
    let extremes = this.highchart1?.xAxis[0].getExtremes();
    if (!this.updating && extremes != null) {
      this.updating = true;
      this.highchart2?.xAxis[0].setExtremes(extremes.min, extremes.max);
    } else {
      this.updating = false;
    }
  }
  drawWeatherSymbols() {
    if (this.highchart1 && this.highchart1.renderer) {
      if (this.imageContainer) {
        this.imageContainer.destroy();
        this.imageContainer = undefined;
      }
      let xAxis = this.highchart1.xAxis[0];
      //Retract/add an hour to min and max comparison, otherwise Highcharts visible points might not get an icon (bug in HighCharts?)
      let visibleMin = xAxis!.min! - 3600000;
      let visibleMax = xAxis!.max! + 3600000;
     this.imageContainer = this.highchart1.renderer.g('imageContainer').add().toFront();
      this.highchart1.series[0].data.forEach((point: Highcharts.Point, i) => {
        // Add only within x-axis visible range
        if (point.x >= visibleMin && point.x <= visibleMax) {
          if (point.plotX && point.plotY) {
            this.highchart1?.renderer
              .image(
                this.chart1Data.symbols[i],
                point.plotX + this.highchart1.plotLeft - 13,
                this.highchart1.plotTop,
                25,
                25
              )
              .attr({
                zIndex: 5
              })
              .add(this.imageContainer).toFront();
          }
        }
      });
    };
  }
}
