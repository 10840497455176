import { Component, Input, OnChanges } from '@angular/core';
import { TranslatedProduct } from '../model/translated-product';
import { DataService } from "../data.service";
import { TranslocoService } from '@ngneat/transloco';
import { saveAs } from "file-saver";
import { tick } from '@angular/core/testing';

@Component({
  selector: 'app-product-metadata',
  templateUrl: './metadata.component.html',
})
export class MetadataComponent implements OnChanges {
  @Input() product: TranslatedProduct | null;
  updateFrequencyText: string | null;
  constructor(private translocoService: TranslocoService, private dataService: DataService) {
  }

  ngOnChanges() {
    if (this.product?.updateFrequencyInHours) {
      this.updateFrequencyText = this.getFrequencyTextFromHours(this.product.updateFrequencyInHours);
    }
  }
  openDocument(product: TranslatedProduct) {
    if (product) {
      if (product.productTypeId === 100) {
        this.dataService.productClicked(product.id).subscribe(result => {
        });
      }
      this.dataService.getFile(product.files[0], product.id)
        .subscribe(data => saveAs(data, product.files[0]));
    }
  }

  getFrequencyTextFromHours(hours: number): string | null {
    if (hours <= 3)
      return this.translocoService.translate('frequency.third');
    else if (hours <= 6)
      return this.translocoService.translate('frequency.sixth');
    else if (hours <= 9)
      return this.translocoService.translate('frequency.nineth');
    else if (hours <= 12)
      return this.translocoService.translate('frequency.twelfth');
    else if (hours <= 24)
      return this.translocoService.translate('frequency.daily');
    else if (hours <= 48)
      return this.translocoService.translate('frequency.everyOtherDay');
    else if (hours <= 72)
      return this.translocoService.translate('frequency.everyThirdDay');
    else if (hours <= 168)
      return this.translocoService.translate('frequency.weekly');
    else if (hours <= 336)
      return this.translocoService.translate('frequency.everyOtherWeek');
    else if (hours <= 672)
      return this.translocoService.translate('frequency.monthly');
    else
      return this.translocoService.translate('frequency.lessThanMonthly');
  }
}
