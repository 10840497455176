import { Injectable, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImageHelper {
  constructor() { }

  getImageData(fileName: string | undefined, data: any): any {
    if (fileName) {
      let fileExtension = fileName.split('.')[1];
      switch (fileExtension.toLowerCase()) {
        case 'jpeg':
        case 'jpg':
          return 'data:image/jpeg;base64,' + data;
        case 'gif':
          return 'data:image/gif;base64,' + data;
        case 'png':
          return 'data:image/x-png;base64,' + data;
        case 'svg':
          return 'data:image/svg+xml;base64,' + data;
        case 'bmp':
          return 'data:image/bmp;base64,' + data;
        case 'tif':
        case 'tiff':
          return 'data:image/tiff;base64,' + data;
        default:
          return 'data:application/octet-stream;base64,' + data;
      }
    }
    else
      return 'data:image/jpeg;base64,' + data;
  }
}
