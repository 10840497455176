import { Pipe, PipeTransform } from '@angular/core';
import { TranslatedProduct } from '../model/translated-product'

@Pipe({
  name: 'productSearch'
})

export class ProductSearchPipe implements PipeTransform {
  transform(products: TranslatedProduct[], searchInput: string, selectedOperation: string, onlyLowBandwidth: boolean, sortOrder: string, sortColumn: string): any[] {
    let tempProducts = products;
    if (onlyLowBandwidth)
      tempProducts = products.filter(x => x.isLowBandwidthProduct);

    if (!searchInput && !selectedOperation) {
      return this.sortProducts(tempProducts, sortOrder, sortColumn);
    }
    else if (searchInput && selectedOperation) {
      return this.sortProducts(tempProducts.filter(x => (x.name.toLowerCase().includes(searchInput.toLowerCase())) || x.place.toLowerCase().includes(searchInput.toLowerCase()) && x.operation.includes(selectedOperation)), sortOrder, sortColumn);
    }
    if (searchInput) {
      return this.sortProducts(tempProducts.filter(x =>
           x.name.toLowerCase().includes(searchInput.toLowerCase())
        || x.place.toLowerCase().includes(searchInput.toLowerCase())
        || x.metadata.toLowerCase().includes(searchInput.toLowerCase())
      ), sortOrder, sortColumn);
    }
    else {
      return this.sortProducts(tempProducts.filter(x => x.operation.includes(selectedOperation)), sortOrder, sortColumn);
    }
  }

  sortProducts(products: any[], sortOrder: string, sortColumn: string): any[] {
    const byFirstNameDesc = createCompareFn<any>(sortColumn, sortOrder)
    products.sort(byFirstNameDesc);
    return products;
  }
  
}

function createCompareFn<T extends Object>(
  property: keyof T,
  sort_order: string
) {
  const compareFn = (a: T, b: T): number => {
    const val1 = a[property] as unknown;
    const val2 = b[property] as unknown;
    const order = sort_order !== "desc" ? 1 : -1;

    switch (typeof val1) {
      case "number": {
        const valb = val2 as number;
        const result = val1 - valb;
        return result * order;
      }
      case "string": {
        const valb = val2 as string;
        const result = val1.localeCompare(valb, 'sv');
        return result * order;
      }
      // add other cases like boolean, etc.
      default:
        return 0;
    };
  }
  return compareFn;
}
