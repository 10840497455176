import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslatedProduct } from '../model/translated-product';
import { MatSliderChange } from '@angular/material/slider';

@Component({
  selector: 'app-image-series-dialog',
  templateUrl: './image-series-dialog.component.html',
})

export class ImageSeriesDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: TranslatedProduct, public dialogRef: MatDialogRef<ImageSeriesDialogComponent>) { }

  product: TranslatedProduct | null;  

  ngOnInit() {
    this.product = this.data;
  }

  close() {   
      this.dialogRef.close(null);
  }
}
