<div *ngIf="selectedItems" class="table-product">
  <div>
    <span class="info-block">
      <b>Position: </b>{{lat | latitudeDirection}}  {{lon | longitudeDirection}}, <b>Z0=</b>{{height}}<b> m</b>
    </span>
  </div>
  <table style="overflow-x: scroll;">
    <tbody>
      <tr>
        <td style="color: #2b2827; font-weight: bold; font-size: 0.8em">Altitude (hft)</td>
        <td *ngFor="let field of timeSlots">
        </td>
      </tr>
      <tr *ngFor="let item of selectedItems">
        <td style="background-color: #f2f0ee !important; font-size: 0.6em; font-weight: bold; padding-right: 10px"><div style="transform: rotate(-45deg);">{{item.Height | number:'3.0'}}</div></td>
        <td *ngFor="let field of item.Columns " style="border:1px solid black; font-size:0.6em">
          <div *ngIf="!field.NoValuesFound">
            <div style="font-size:2em" [ngStyle]="{'transform': 'rotate(' + field.Direction + 'deg)'}"> <b>↓</b></div>
            <span style="text-align:left;">{{field.Direction}}°/{{field.Velocity}}m/s</span><br />
            <span style="text-align:right;" [ngStyle]="{'color': field.Temperature>0 ? 'red' : 'blue'}">{{field.Temperature}}°C</span>
          </div>
          <div *ngIf="field.NoValuesFound">
            <div style="height:62px;"></div>
          </div>
        </td>
      </tr>
      <tr>
        <td></td>
        <td *ngFor="let field of timeSlots" style=" font-size: 0.6em; font-weight: bold; z-index: 9">
          <div>{{ field | slice:11:16}}Z</div>
          <div>{{ field | slice:8:10 }}/{{ field | slice:5:7 }} </div>
          <div>{{ field | slice:0:4 }}</div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
