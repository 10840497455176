import { Component } from '@angular/core';
import { Observable } from "rxjs";
import { DataStore } from '../data.store';
import { LoggingService } from '../logging/logging.service';
import { TranslatedProduct } from '../model/translated-product';

@Component({
  selector: 'joint',
  templateUrl: './joint.component.html',
})
export class JointComponent {
  products: Observable<Array<TranslatedProduct>>;
  jointheader: string;
  jointText: string;

  constructor(private dataStore: DataStore, private loggingService: LoggingService) {
    this.products = this.dataStore.jointProducts;

    this.dataStore.jointPageHeader.subscribe(res => {
      this.jointheader = res;
    }, error => console.error(error));

    this.dataStore.jointPageText.subscribe(res => {
      this.jointText = res;
    }, error => console.error(error));
  }
}
