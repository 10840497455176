import { Injectable, ErrorHandler } from '@angular/core';
import { LoggingService } from './logging/logging.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

  constructor(private router: Router, private loggingService: LoggingService) {
    super();
  }

  handleError(error: Error) {
    this.loggingService.logException(error); // Manually log exception
    this.router.navigate(['/error']);
  }
}
