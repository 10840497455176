
<div *ngIf="isExternalDeployment" class="c-grid">
  <div class="header-sticky-priority">
    <section class="s-cookies" id="fm-cookie-banner" #fmCookieBanner role="dialog" style="transform: translateY(100%);">
      <div class="gc">
        <div class="g-1 -text-s">
          <span>{{ "cookieMessage" | transloco }}</span>
          <button class="close" (click)="acceptCookies();">
            <svg class="svg-i-close" viewBox="0 0 512 512" id="i-close"><path d="M274.329 255.503l135.146-135.146-19.316-19.317-135.145 135.146L119.869 101.04l-19.317 19.317 135.146 135.146-135.146 135.145 19.317 19.316 135.145-135.146zm19.297 19.286l115.83 115.83-19.304 19.306L274.32 294.094z"></path></svg>
          </button>
        </div>
      </div>
    </section>
  </div>
</div>
<header class="main-header">
  <div *ngIf="isAuthenticated" class="main-header main-header__top">
    <app-logo></app-logo>
    <ul class="main-menu main-menu--settings" style="position: absolute; right: 1rem;">
      <li class="language-choice" [ngClass]="swedishSelected ? '' : 'active'"><a (click)="changeSiteLanguage('en')">English</a></li>
      <li class="language-choice" [ngClass]="swedishSelected ? 'active' : ''"><a class="" (click)="changeSiteLanguage('sv')">Svenska</a></li>
      <li style="margin-left:2rem;">
        <button class="hamburger" role="switch" (click)="toggleMenuState()" #dataHamburger data-js-hamburger="" aria-label="Menu">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </li>
    </ul>
  </div>
  <div *ngIf="isAuthenticated" class="main-menu main-menu--mobile" #dataMainMenu data-js-main-menu="">
    <nav role="navigation">
      <ul class="main">
        <li [routerLinkActive]="['active']"><a (click)="ensureClosedMenuState()" [routerLink]="['/home']">{{ "navigation.main.start" | transloco }}</a></li>
        <li [routerLinkActive]="['active']"><a (click)="ensureClosedMenuState()" [routerLink]="['/all']">{{ "navigation.main.all" | transloco }}</a></li>
        <li [routerLinkActive]="['active']"><a (click)="ensureClosedMenuState()" [routerLink]="['/myFavorites']">{{ "navigation.main.myFavorites" | transloco }}</a></li>
        <li [routerLinkActive]="['active']"><a (click)="ensureClosedMenuState()" [routerLink]="['/air']">{{ "navigation.main.air" | transloco }}</a></li>
        <li [routerLinkActive]="['active']"><a (click)="ensureClosedMenuState()" [routerLink]="['/land']">{{ "navigation.main.land" | transloco }}</a></li>
        <li [routerLinkActive]="['active']"><a (click)="ensureClosedMenuState()" [routerLink]="['/sea']">{{ "navigation.main.sea" | transloco }}</a></li>
        <li [routerLinkActive]="['active']"><a (click)="ensureClosedMenuState()" [routerLink]="['/space']">{{ "navigation.main.space" | transloco }}</a></li>
        <li [routerLinkActive]="['active']"><a (click)="ensureClosedMenuState()" [routerLink]="['/joint']">{{ "navigation.main.joint" | transloco }}</a></li>
        <li [routerLinkActive]="['active']"><a (click)="ensureClosedMenuState()" [routerLink]="['/operation']">{{ "navigation.main.operation" | transloco }}</a></li>
        <li [routerLinkActive]="['active']"><a (click)="ensureClosedMenuState()" [routerLink]="['/contact']">{{ "navigation.main.contact" | transloco }}</a></li>
        <li [routerLinkActive]="['active']">
          <a style="cursor:pointer;">
            <svg class="svg-i-person-circle" viewBox="0 0 512 512" id="i-person-circle"><path d="M255.89 0C114.793 0 0 114.792 0 255.891 0 396.988 114.792 511.78 255.89 511.78s255.89-114.791 255.89-255.889C511.78 114.792 396.988 0 255.89 0zm0 486.19c-126.998 0-230.301-103.302-230.301-230.3 0-126.974 103.303-230.302 230.301-230.302S486.191 128.916 486.191 255.89c0 126.999-103.303 230.3-230.301 230.3zm-56.707-305.843c0-31.302 25.405-56.709 56.707-56.709 31.304 0 56.708 25.405 56.708 56.709 0 31.303-25.402 56.709-56.708 56.709-31.303 0-56.707-25.407-56.707-56.709zm209.263 159.328c0-47.781-68.302-86.513-152.556-86.513s-152.555 38.731-152.555 86.513c0 38.837 305.111 38.839 305.111 0z"></path></svg> {{ this.userName }}
          </a>
        </li>
        <li *ngIf="isExternalDeployment && loginDisplay" style="cursor:pointer;"><a (click)="logout()">{{ "navigation.logout" | transloco }}</a></li>
      </ul>
    </nav>
  </div>
  <div *ngIf="!isAuthenticated" class="logging-in-message">
    <h3>{{ "logginIn" | transloco }}</h3>
  </div>
</header>
<section class="main-content">
  <div *ngIf="isAuthenticated" class="profilebar profileBarIsStuck main-language">
    <app-logo></app-logo>
    <div class="profilebar-right-section">
      <div style="display:inline-flex;">
        <ul class="main-menu main-menu--desktop" style="margin-right:2rem;">
          <li [ngClass]="swedishSelected ? '' : 'active'"><a (click)="changeSiteLanguage('en')">English</a></li>
          <li [ngClass]="swedishSelected ? 'active' : ''"><a class="" (click)="changeSiteLanguage('sv')">Svenska</a></li>
        </ul>
        <a>
          {{ this.userName }} <svg class="svg-i-person-circle" viewBox="0 0 512 512" id="i-person-circle"><path d="M255.89 0C114.793 0 0 114.792 0 255.891 0 396.988 114.792 511.78 255.89 511.78s255.89-114.791 255.89-255.889C511.78 114.792 396.988 0 255.89 0zm0 486.19c-126.998 0-230.301-103.302-230.301-230.3 0-126.974 103.303-230.302 230.301-230.302S486.191 128.916 486.191 255.89c0 126.999-103.303 230.3-230.301 230.3zm-56.707-305.843c0-31.302 25.405-56.709 56.707-56.709 31.304 0 56.708 25.405 56.708 56.709 0 31.303-25.402 56.709-56.708 56.709-31.303 0-56.707-25.407-56.707-56.709zm209.263 159.328c0-47.781-68.302-86.513-152.556-86.513s-152.555 38.731-152.555 86.513c0 38.837 305.111 38.839 305.111 0z"></path></svg>
        </a>
        <div *ngIf="isExternalDeployment" class="login-logout-controls">
          <div *ngIf="!loginDisplay">
            <button (click)="loginRedirect()">{{ "navigation.login" | transloco }}</button>
          </div>
          <div *ngIf="loginDisplay">
            <button (click)="logout()">{{ "navigation.logout" | transloco }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="main-menu--desktop--container">
      <ul class="main-menu main-menu--desktop">
        <li [routerLinkActive]="['active']">
          <a [routerLink]="['/home']">{{ "navigation.main.start" | transloco }}</a>
        </li>
        <li [routerLinkActive]="['active']">
          <a [routerLink]="['/all']">{{ "navigation.main.all" | transloco }}</a>
        </li>
        <li [routerLinkActive]="['active']">
          <a [routerLink]="['/myFavorites']">{{ "navigation.main.myFavorites" | transloco }}</a>
        </li>
        <li [routerLinkActive]="['active']">
          <a [routerLink]="['/air']">{{ "navigation.main.air" | transloco }}</a>
        </li>
        <li [routerLinkActive]="['active']">
          <a [routerLink]="['/land']">{{ "navigation.main.land" | transloco }}</a>
        </li>
        <li [routerLinkActive]="['active']">
          <a [routerLink]="['/sea']">{{ "navigation.main.sea" | transloco }}</a>
        </li>
        <li [routerLinkActive]="['active']">
          <a [routerLink]="['/space']">{{ "navigation.main.space" | transloco }}</a>
        </li>
        <li [routerLinkActive]="['active']">
          <a [routerLink]="['/joint']">{{ "navigation.main.joint" | transloco }}</a>
        </li>
        <li [routerLinkActive]="['active']">
          <a [routerLink]="['/operation']">{{ "navigation.main.operation" | transloco }}</a>
        </li>
        <li [routerLinkActive]="['active']">
          <a [routerLink]="['/contact']">{{ "navigation.main.contact" | transloco }}</a>
        </li>
      </ul>
    </div>
  </div>
  <div #dataGrid [@routeAnimations]="o && o.activatedRouteData && o.activatedRouteData['animation']">
    <router-outlet #o="outlet" (activate)="onActivate($event)"></router-outlet>
  </div>
  <div class="wcag-link" *ngIf="isExternalDeployment"><a href="https://www.forsvarsmakten.se/sv/tillganglighetsredogorelse/" target="_blank">{{ "wcagText" | transloco }}</a></div>
</section>


