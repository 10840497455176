<div class="info-block" [class.is-active]="product != null" *ngIf="product">
  <div class="place-label"> {{ "product.lastUpdated" | transloco }} {{ product.latestUpdateWithTime }}</div>
  <ul>
    <li class="is-signed" *ngIf="product.isSigned === false">
      {{ "product.automaticGeneratedProduct" | transloco }}
    </li>
    <li *ngIf="product.productTypeId >= 100 && product.productTypeId < 200">
      <a class="product-download-link" (click)="openDocument(product);">{{ "product.download" | transloco }}</a>
    </li>
    <li>
      <div>{{ product.description }}</div>
    </li>
    <li>
      <span>{{ "product.arena" | transloco }}: </span>{{ product.arena }}
      <span *ngIf="product.operation">{{ "product.operation" | transloco }}: </span>  {{ product.operation }}
      <span>{{ "product.validfrom" | transloco }}: </span>{{ product.validFrom }}
      <span>{{ "product.validto" | transloco }}: </span>{{ product.validTo }}
      <span *ngIf="product.updateFrequencyInHours">{{ "product.updatefrequencyinhours" | transloco }}: </span>  {{ updateFrequencyText }}
      <span>{{ "product.tags" | transloco }}</span><span class="tags" *ngFor="let tag of product.tags; let last = last"> {{tag}}<ng-container *ngIf="!last">,</ng-container> </span> 
    </li>
    <li class="low-bandwidth" *ngIf="product.isLowBandwidthProduct">
      <svg *ngIf="product.isLowBandwidthProduct" viewBox="0 0 12 20" id="i-device" class="icon svg-i-device">
        <path d="M11.5 20H.5a.5.5 0 01-.5-.5V.5A.5.5 0 01.5 0h11a.5.5 0 01.5.5v19a.5.5 0 01-.5.5zM1 19h10V1H1v18zm6-1.5a.5.5 0 00-.5-.5h-1a.5.5 0 000 1h1a.5.5 0 00.5-.5z" />
      </svg><span>{{ "product.islowbandwidthproduct" | transloco }}</span>
    </li>
    <li class="is-updated" *ngIf="!product.isUpdated">
      <svg *ngIf="!product.isUpdated" viewBox="0 0 20 20" id="i-time" class="icon svg-i-time" style="fill: #e87e0c">
        <path d="M14.5 10.5a.5.5 0 01-.5.5H9.5a.5.5 0 01-.5-.5v-6a.5.5 0 011 0V10h4a.5.5 0 01.5.5zM20 10c0 5.514-4.486 10-10 10S0 15.514 0 10 4.486 0 10 0s10 4.486 10 10zm-1 0c0-4.962-4.037-9-9-9s-9 4.038-9 9c0 4.963 4.037 9 9 9s9-4.037 9-9z" />
      </svg><span>{{ "product.isnotupdated" | transloco }}</span>
    </li>
  </ul>
</div>
