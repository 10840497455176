import { Config } from './config';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  config: Config;
  
  loadConfig(input: Config) {
    this.config = input;
  }
}
