import { Component } from '@angular/core';
import { DataStore } from '../data.store';
import { LoggingService } from '../logging/logging.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent {
  header: string;
  introText: string;
  constructor(private dataStore: DataStore, private loggingService: LoggingService) {
    
    this.dataStore.startPageHeader.subscribe(res => {
      this.header = res;
    }, error => console.error(error));

    this.dataStore.startPageIntroText.subscribe(res => {
      this.introText = res;
    }, error => console.error(error));
  }
}
