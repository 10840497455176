import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'latitudeDirection'
})

export class LatitudeDirection implements PipeTransform {
  transform(value: any): string {

    const latitudeStr: string = String(value);

    let direction: string;

    if (latitudeStr.startsWith("-")) {
      direction = "S"
    }
    else {
      direction = "N"
    }

    return `${value}${direction}`
  }
}
