import { Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import Exporting from 'highcharts/modules/exporting';
import Windbarb from 'highcharts/modules/windbarb';
Exporting(Highcharts);
Windbarb(Highcharts);

@Component({
  selector: 'app-graph-double',
  templateUrl: './double-graph.component.html',
  standalone: true,
  imports: [HighchartsChartModule]
})

export class DoubleGraphComponent {
  public highcharts = Highcharts;
  updating = false;

  symbols = ["clearsky_day.png",
    "heavysnowshowers_night.png", "heavysnowshowers_night.png",
    "heavyrainshowers_night.png", "heavyrainshowers_day.png", "heavyrainshowers_day.png",
    "clearsky_day.png", "clearsky_day.png", "clearsky_day.png",
    "clearsky_day.png", "clearsky_day.png", "clearsky_day.png",
    "clearsky_day.png", "clearsky_day.png", "clearsky_day.png",
    "clearsky_day.png", "clearsky_day.png", "clearsky_day.png",
    "clearsky_day.png", "clearsky_day.png", "clearsky_day.png",
    "clearsky_day.png", "clearsky_day.png", "clearsky_day.png",
    "clearsky_day.png", "clearsky_day.png", "clearsky_day.png",
    "clearsky_day.png", "clearsky_day.png", "clearsky_day.png",
    "clearsky_day.png", "clearsky_day.png", "clearsky_day.png",
    "clearsky_day.png", "clearsky_day.png", "clearsky_day.png",
    "clearsky_day.png", "clearsky_day.png", "clearsky_day.png",
    "clearsky_day.png", "clearsky_day.png", "clearsky_day.png",
    "clearsky_day.png", "clearsky_day.png", "clearsky_day.png",
    "clearsky_day.png", "clearsky_day.png", "clearsky_day.png",
    "clearsky_day.png", "clearsky_day.png", "clearsky_day.png",
    "clearsky_day.png", "clearsky_day.png", "clearsky_day.png",
    "clearsky_day.png", "clearsky_day.png", "clearsky_day.png",
    "clearsky_day.png", "clearsky_day.png", "clearsky_day.png",
    "clearsky_day.png", "clearsky_day.png", "clearsky_day.png",
    "clearsky_day.png", "clearsky_day.png",];
  //Ground data (graph 1)
  t = [-1.55,
  -1.25,
  -1.05,
  -0.55,
  -0.05,
    0.85,
    1.05,
    1.05,
    1.50,
    1.90,
    1.90,
    2.45,
    2.75,
    2.35,
    2.95,
    3.25,
    3.25,
    3.05,
    3.15,
    3.45,
    3.55,
    3.75,
    3.45,
    3.15,
    3.85,
    4.65,
    5.35,
    5.25,
    5.15,
    5.45,
    5.65,
    5.75,
    5.75,
    5.95,
    5.95,
    6.55,
    7.35,
    7.35,
    7.55,
    7.65,
    7.55,
    7.45,
    7.55,
    7.85,
    8.65,
    8.55,
    8.55,
    8.45,
    8.45,
    7.95,
    7.85,
    8.65,
    9.45,
    8.95,
    9.35,
    6.45,
    5.75,
    5.85,
    5.85,
    5.95,
    6.15,
    5.85,
    4.65,
    5.55,
    4.75];
  td = [-2.55,
  -2.25,
  -1.05,
  -0.55,
  -0.05,
    0.85,
    1.55,
    2.05,
    2.50,
    2.90,
    2.90,
    3.35,
    3.75,
    3.35,
    3.95,
    4.25,
    4.25,
    4.05,
    4.15,
    4.45,
    4.55,
    4.75,
    4.45,
    4.15,
    4.85,
    4.65,
    2.85,
    1.95,
    1.85,
    2.45,
    2.15,
    2.15,
    2.45,
    3.25,
    4.15,
    3.75,
    3.45,
    4.45,
    5.15,
    5.85,
    6.05,
    6.25,
    6.25,
    6.55,
    7.05,
    7.05,
    7.15,
    7.05,
    7.05,
    6.75,
    6.45,
    4.85,
    7.25,
    6.15,
    6.85,
    5.45,
    2.75,
    3.35,
    2.85,
    2.45,
    3.35,
    3.25,
    1.75,
    0.75,
    2.25
  ];
  rh = [93,
    89,
    89,
    91,
    89,
    90,
    89,
    91,
    93,
    95,
    97,
    95,
    95,
    91,
    90,
    85,
    78,
    81,
    84,
    85,
    83,
    86,
    82,
    79,
    76,
    91,
    84,
    79,
    79,
    81,
    78,
    78,
    79,
    83,
    88,
    82,
    76,
    82,
    85,
    88,
    90,
    92,
    91,
    91,
    90,
    90,
    91,
    91,
    91,
    92,
    91,
    77,
    86,
    83,
    84,
    93,
    81,
    84,
    81,
    78,
    82,
    83,
    81,
    71,
    84
  ];
  prec = [0,
    0,
    0,
    0,
    0.2,
    0.5,
    1,
    1,
    1.02,
    1.08,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0.01,
    0.08,
    0.11,
    0.76,
    0.1,
    1.56,
    1.19,
    0.05,
    0.04,
    0.01,
    0,
    0,
    0,
    0,
    0.04,
    0,
    0.05,
    2.56,
    5.62,
    2.46,
    0.78,
    0.05,
    0,
    0.59,
    2.41,
    2.21,
    0.15,
    0,
    0.01,
    0.16,
    0.01,
    0,
    0,
    0,
    0,
    0.11,
    0.4,
    0.01,
    0.04,
    0,
    0,
    0.09,
    2.1,
    0.28,
    0.76
  ];
  spres = [2.10,
    2,
    1.90,
    1.80,
    1.50,
    1.50,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0];
  //Wind data (graph 2)
  wdir10 = [
    [1, 239.7],
    [1, 223.2],
    [1, 216.4],
    [1, 218.2],
    [1, 221.5],
    [1, 246.9],
    [1, 253.5],
    [1, 246.7],
    [1, 243.2],
    [1, 249.9],
    [1, 225.0],
    [1, 216.9],
    [1, 229.6],
    [1, 245.4],
    [1, 228.7],
    [1, 213.9],
    [1, 201.8],
    [1, 200.4],
    [1, 204.3],
    [1, 199.9],
    [1, 202.9],
    [1, 200.2],
    [1, 188.3],
    [1, 176.5],
    [1, 176.1],
    [1, 267.5],
    [1, 254.2],
    [1, 248.8],
    [1, 257.5],
    [1, 263.6],
    [1, 260.8],
    [1, 258.3],
    [1, 223.6],
    [1, 219.9],
    [1, 210.1],
    [1, 208.5],
    [1, 212.2],
    [1, 216.6],
    [1, 225.1],
    [1, 233.3],
    [1, 242.2],
    [1, 237.9],
    [1, 230.9],
    [1, 222.2],
    [1, 231.3],
    [1, 237.6],
    [1, 236.1],
    [1, 234.7],
    [1, 238.6],
    [1, 240.9],
    [1, 234.0],
    [1, 240.5],
    [1, 226.3],
    [1, 215.1],
    [1, 233.8],
    [1, 308.6],
    [1, 303.4],
    [1, 293.1],
    [1, 269.2],
    [1, 216.7],
    [1, 181.9],
    [1, 217.3],
    [1, 272.3],
    [1, 267.0],
    [1, 274.5]
  ];
  wspd10 = [
    6.1,
    7.2,
    7.4,
    7.3,
    6.9,
    6.4,
    7.6,
    7.2,
    6.3,
    4.4,
    5.3,
    6.4,
    5.7,
    4.8,
    4.9,
    6.3,
    7.6,
    8.7,
    8.8,
    8.8,
    8.6,
    7.4,
    8.9,
    8.1,
    9.3,
    10.5,
    10.1,
    10.2,
    10.0,
    11.2,
    11.3,
    9.4,
    9.4,
    10.0,
    11.2,
    12.0,
    12.8,
    14.1,
    14.2,
    14.1,
    12.1,
    11.5,
    11.6,
    13.2,
    14.0,
    14.2,
    13.6,
    13.2,
    12.8,
    11.6,
    10.6,
    6.7,
    8.1,
    8.5,
    8.4,
    7.8,
    10.0,
    9.7,
    8.0,
    5.1,
    7.8,
    7.3,
    4.8,
    5.3,
    7.6
  ];
  wgust10 = [
    9.0,
    9.2,
    9.6,
    9.6,
    9.1,
    7.9,
    9.6,
    9.3,
    8.2,
    6.5,
    6.5,
    8.0,
    7.4,
    6.3,
    5.9,
    7.6,
    9.5,
    11.1,
    11.5,
    11.3,
    11.9,
    9.7,
    11.0,
    10.1,
    12.4,
    15.7,
    13.9,
    13.5,
    13.7,
    15.1,
    15.6,
    14.8,
    12.1,
    13.4,
    14.9,
    16.2,
    17.6,
    19.2,
    19.3,
    19.8,
    18.6,
    16.2,
    15.3,
    17.4,
    19.5,
    19.2,
    19.0,
    18.0,
    17.7,
    16.9,
    15.1,
    13.7,
    10.0,
    10.7,
    10.9,
    13.3,
    13.0,
    13.7,
    12.7,
    10.4,
    10.2,
    10.5,
    11.2,
    7.4,
    11.1
  ];
  chart1Options: any = {
    chart: {
      zoomType: 'x',
      panning: true,
      panKey: 'shift'
    },
    title: {
      text: 'Markdata',
      align: 'left',
      style: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }
    },
    tooltip: {
      shared: true
    },
    xAxis: [{ // Bottom X axis
      type: 'datetime',
      tickInterval: 3 * 36e5, // three hours
      tickLength: 0,
      startOnTick: false,
      endOnTick: false,
      minPadding: 0,
      maxPadding: 0,
      //offset: 30,
      showLastLabel: true,
      labels: {
        format: '{value:%H}',

      },
      crosshair: true,
      events: {
        afterSetExtremes: () => { this.setExtremes2() }
      }
    }, { // Top X axis
      linkedTo: 0,
      type: 'datetime',
      tickInterval: 24 * 3600 * 1000,
      labels: {
        format: '{value:<span style="font-size: 12px; font-weight: bold">%a</span> %b %e}',
        align: 'left',
        x: 3,
        y: 8
      },
      opposite: true,
      tickLength: 20,
      gridLineWidth: 1
    }],
    yAxis: [{ // temperatur
      title: {
        text: 'Temperatur'
      },
      labels: {
        format: '{value}°'
      }
    }, { // daggpunktstemperatur
      title: {
        text: null
      },
      labels: {
        format: '{value}°'
      }
    }, { // prec
      title: { // Title on top of axis
        text: 'Nederbörd'
      },
      opposite: true,
    }, { // Tertiary yAxis
      gridLineWidth: 0,
      title: {
        text: 'Sea-Level Pressure'
      },
      labels: {
        format: '{value} xx'
      },
      opposite: true
    }],
    plotOptions: {
      series: {
        pointPlacement: 'between',
        pointInterval: 10800000, // one hour
        pointStart: Date.UTC(2023, 4, 13, 0, 0, 0),
        stacking: 'normal'
      },
    },
    series: [{
      name: 'Temperatur',
      data: this.t,
      type: 'spline',
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: true
          }
        }
      },
      tooltip: {
        pointFormat: '<span style="color:{point.color}">\u25CF</span> ' +
          '{series.name}: <b>{point.y}°C</b><br/>'
      },
      zIndex: 1,
      color: 'red',
      negativeColor: 'blue'
    }, {
      name: 'Daggpunktstemperatur',
      data: this.td,
      type: 'spline',
      dashStyle: 'Dash',
      marker: {
        enabled: false
      },
      tooltip: {
        pointFormat: '<span style="color:{point.color}">\u25CF</span> ' +
          '{series.name}: <b>{point.y}°C</b><br/>'
      },
      zIndex: 1,
      color: 'red',
      negativeColor: 'blue'
    },
    {
      name: 'Regn',
      color: 'green',
      type: 'column',
      data: this.prec,
      groupPadding: 0,
      pointPadding: 0,
      tooltip: {
        valueSuffix: ' mm'
      },
      yAxis: 2
    }, {
      name: 'Snö',
      color: '#48AFE8',
      type: 'column',
      data: this.spres,
      groupPadding: 0,
      pointPadding: 0,
      tooltip: {
        valueSuffix: ' mm'
      },
      yAxis: 2
      },
      {
        name: 'Sea-Level Pressure',
        type: 'spline',
        yAxis: 3,
        data: this.wgust10,
        marker: {
          enabled: false
        },
        dashStyle: 'shortdot',
        tooltip: {
          valueSuffix: ' mb'
        }

      }]
  };
  chart2Options: any = {
    chart: {
      zoomType: 'x',
      panning: true,
      panKey: 'shift'
    },
    title: {
      text: 'Vind',
      align: 'left'
    },
    tooltip: {
      shared: true
    },
    xAxis: [{ // Bottom X axis
      type: 'datetime',
      tickInterval: 3 * 36e5, // three hours
      tickLength: 0,
      startOnTick: false,
      endOnTick: false,
      minPadding: 0,
      maxPadding: 0,
      offset: 30,
      showLastLabel: true,
      labels: {
        format: '{value:%H}'
      },
      crosshair: true,
      events: {
        afterSetExtremes: () => { this.setExtremes1() }
      }
    }, { // Top X axis
      linkedTo: 0,
      type: 'datetime',
      tickInterval: 24 * 3600 * 1000,
      labels: {
        format: '{value:<span style="font-size: 12px; font-weight: bold">%a</span> %b %e}',
        align: 'left',
        x: 3,
        y: 8
      },
      opposite: true,
      tickLength: 20,
      gridLineWidth: 1
    }],
    yAxis: [{ // wgust10
      title: {
        text: null
      },
      labels: {
        format: '{value}m/s'
      }

    }, { // wspd10
      title: {
        text: null
      },
      labels: {
        format: '{value}m/s'
      }
    }],
    plotOptions: {
      series: {
        pointPlacement: 'between',
        pointInterval: 10800000, // one hour
        pointStart: Date.UTC(2023, 4, 13, 0, 0, 0)
      },
    },
    series: [{
      name: 'Byvind',
      data: this.wgust10,
      type: 'spline',
      dashStyle: 'Dash',
      marker: {
        enabled: false
      },
      color: '#48AFE8'
    }, {
      name: 'Vindhastighet',
      color: '#48AFE8',
      data: this.wspd10,
      type: 'spline',
      dashStyle: 'Solid',
      marker: {
        enabled: false
      }
    }, {
      name: 'Vindriktning',
      type: 'windbarb',
      id: 'windbarbs',
      color: '#48AFE8',
      lineWidth: 1.5,
      data: this.wdir10,
      vectorLength: 18,
      yOffset: -13
    }]
  };
  highchart1: Highcharts.Chart | undefined;
  highchart2: Highcharts.Chart | undefined;

  ngOnInit() {
    console.log('Testgraf renderas...');
    this.highchart1 = this.highcharts.chart('highchart1Container', this.chart1Options);
    //this.highchart1.reflow();
    this.highchart2 = this.highcharts.chart('highchart2Container', this.chart2Options);

    this.drawWeatherSymbols(this.highchart1);
  }

  setExtremes1() {
    let extremes = this.highchart2?.xAxis[0].getExtremes();
    if (!this.updating && extremes != null) {
      this.updating = true;
      this.highchart1?.xAxis[0].setExtremes(extremes.min, extremes.max);
      if (this.highchart1)
        this.drawWeatherSymbols(this.highchart1);
    } else {
      this.updating = false;
    }
  }
  setExtremes2() {
    let extremes = this.highchart1?.xAxis[0].getExtremes();
    if (!this.updating && extremes != null) {
      this.updating = true;
      this.highchart2?.xAxis[0].setExtremes(extremes.min, extremes.max);
      if (this.highchart1)
        this.drawWeatherSymbols(this.highchart1);
    } else {
      this.updating = false;
    }
  }
  imageContainer: Highcharts.SVGElement | undefined;

  drawWeatherSymbols(chart: Highcharts.Chart) {
    if (this.imageContainer) {
      this.imageContainer.destroy();
      this.imageContainer = undefined;
    }
    this.imageContainer = chart.renderer.g('imageContainer').add().toFront();

    chart.series[0].data.forEach((point: Highcharts.Point, i) => {
      //if (this.resolution > 36e5 || i % 2 === 0) {

      //const [symbol, specifier] = this.symbols[i].split('_'),
      //  icon = Meteogram.dictionary[symbol].symbol +
      //    ({ day: 'd', night: 'n' }[specifier] || '');

      //if (Meteogram.dictionary[symbol]) {

      if (point.plotX && point.plotY) {
        chart.renderer
          .image(
            '/assets/img/weather-icons/' + this.symbols[i],
            point.plotX + chart.plotLeft - 13,
            chart.plotTop,//point.plotY + chart.plotTop - 30
            25,
            25
          )
          .attr({
            zIndex: 5
          })
          .add(this.imageContainer).toFront();
        //} else {
        console.log(this.symbols[i]);
      }
      //}
      //}
    });
  };
}
