import { ViewChild, ElementRef, Component, Injectable, Inject, OnInit } from '@angular/core';
import { DataStore } from "./data.store";
import { AnimationHelper } from './animation.helper';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  template: ''
})


@Injectable()
export abstract class AppBaseComponent {
  isAuthenticated: boolean;
  isInternal: boolean;
  isIframe = false;
  loginDisplay = false;
  isExternalDeployment: boolean;
  selectedLanguageStorageKey = "selectedLanguage";
  hambugerMenuIsOpen = false;
  hamburgerMenuNavItems: any;
  title = '';
  userName = "";
  swedishSelected = true;

  constructor(protected dataStore: DataStore,
    private animationHelper: AnimationHelper,
    private translocoService: TranslocoService,
    @Inject('external') external: boolean) {
    this.isExternalDeployment = external;
    dataStore.userName.subscribe((userName: string) => {
      this.userName = userName
    });
  }

  loadLanguage() {
    let selectedSiteLanguage = this.readFromStorage(this.selectedLanguageStorageKey);
    if (selectedSiteLanguage) {
      this.setLanguage(selectedSiteLanguage, false);
    } else {
      this.setLanguage('sv', false);
    }
  }

  setLanguage(language: string, setLanguageInStore: boolean): void {
    this.translocoService.setActiveLang(language);
    if (language === 'sv')
      this.swedishSelected = true;
    else
      this.swedishSelected = false;

    if (setLanguageInStore)
      this.dataStore.setLanguage();
  }

  changeSiteLanguage(language: string): void {
    this.writeToStroage(this.selectedLanguageStorageKey, language);
    this.setLanguage(language, true);
  }

  setTouchAndBrowser() {
    if (this.isTouch()) {
      // Replace Not modern class
      this.rootClassName('replace', 'no-touch', 'touch');
    }
    if (this.isIE()) {
      this.rootClassName('add', 'is-ie', '');
    }
  }

  readFromStorage(key: string): any {
    const data = localStorage.getItem(key);
    if (data != null) {
      return JSON.parse(data);
    }
    return;
  }

  writeToStroage(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  acceptCookies() {
    this.writeToStroage('fmvwkakor', 'true');
    if (this.fmCookieBannerRef)
      this.fmCookieBannerRef.nativeElement.style = "transform: translateY(300%)";
  }

  @ViewChild('dataProfilebar') profileBarRef: ElementRef | undefined;
  @ViewChild('dataGrid') dataGridRef: ElementRef | undefined;
  @ViewChild('dataMainMenu') dataMainMenuRef: ElementRef | undefined;
  @ViewChild('dataHamburger') dataHamburgerRef: ElementRef | undefined;
  @ViewChild('fmCookieBanner') fmCookieBannerRef: ElementRef | undefined;

  rootClassName(action: string, className: string, newClassName: string) {
    let rootElement = document.documentElement,
      classList = rootElement.className;

    if ('add' === action) {
      classList = classList + ' ' + className;
    } else if ('remove' === action) {
      classList = classList.split(className).join('');
    } else if ('replace' === action) {
      classList = classList.split(className).join(newClassName);
    }
    rootElement.className = classList;
  }

  isTouch() {
    return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));
  }

  isIE() {
    let ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    let is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
    return is_ie;
  }

  //Scrolls to top of page when new route is activated
  onActivate(event: any) {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  ngAfterViewInit() {
    if (this.dataMainMenuRef && this.dataHamburgerRef) {
      this.hambugerMenuIsOpen = this.dataMainMenuRef.nativeElement.classList.contains('open');
      this.hamburgerMenuNavItems = this.dataMainMenuRef.nativeElement.querySelectorAll('.main li');
    }
  }

  ensureClosedMenuState() {
    if (this.hambugerMenuIsOpen)
      this.toggleMenuState();
  }

  toggleMenuState() {
    this.hambugerMenuIsOpen = !this.hambugerMenuIsOpen;
    this.dataMainMenuRef!!.nativeElement.classList.toggle('open');
    this.dataHamburgerRef!!.nativeElement.classList.toggle('open');
    this.animationHelper.animateHamburgerMenuItems(this.hamburgerMenuNavItems, this.hambugerMenuIsOpen);
  }
}

