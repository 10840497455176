import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { ExternalAppComponent } from './app.external.component';
import { HomeComponent } from './home/home.component';
import { AllComponent } from './all/all.component';
import { AirComponent } from './air/air.component';
import { LandComponent } from './land/land.component';
import { SeaComponent } from './sea/sea.component';
import { SpaceComponent } from './space/space.component';
import { JointComponent } from './joint/joint.component';
import { OperationComponent } from './operation/operation.component';
import { ProductListComponent } from './product-list/product-list.component';
import { SortableIconsComponent } from './product-list/sortable-icons.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { MetadataComponent } from './product-details/metadata.component';
import { LogoComponent } from './general-components/logo.component';
import { ConfigService } from './config.service';
import { Config } from './config';
import { LoggingService } from './logging/logging.service';
import { NotFoundComponent } from './general-components/not-found.component';
import { ErrorComponent } from './general-components/error.component';
import { ErrorHandlerService } from './error-handler.service';
import { TranslocoRootModule } from './transloco-root.module';
import { ProductDetailsDialogComponent } from "./product-details/product-details-dialog.component";
import { ProductSearchPipe } from './pipes/product-search.pipe';
import { LatitudeDirection } from './pipes/latitude-direction.pipe';
import { LongitudeDirection } from './pipes/longitude-direction.pipe';
import { ContactComponent } from './contact/contact.component';
import { HeatMap } from './product-details/heat-map.component';
import { GraphComponent } from './product-details/graph.component';
import { DoubleGraphComponent } from './product-details/double-graph.component';
import { TableComponent } from './product-details/table.component';
import { MatSliderModule } from '@angular/material/slider';
import { ImageSeriesDialogComponent } from './product-details/image-series-dialog.component';
import { ImageSeriesPreviewComponent } from './product-details/image-series-preview.component';
import { ImageSeriesComponent } from './product-details/image-series.component';
import { MyFavorites } from './favorites/myFavorites.component';
import { TableTimeSliceComponent } from './product-details/table-timeslice.component';

import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalRedirectComponent } from '@azure/msal-angular';
import { environment } from '../environments/environment';
import { InternalAppComponent } from './app.internal.component';

export const configFactory = (configService: ConfigService) => {
  return () => configService.loadConfig(window['tempConfigStorage']);
};

export function initializeLogging(loggingService: LoggingService) {
  const conf: Config = window['tempConfigStorage'];
  return () => loggingService.initLogger(conf);
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(configService: ConfigService): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: configService.config.clientId,
      authority: configService.config.authority,
      knownAuthorities: [configService.config.knownAuthority],
      redirectUri: '/',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(configService: ConfigService): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map([
    [configService.config.apiEndpoint, [configService.config.apiScope]]
  ])
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

const AzureProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory,
    deps: [ConfigService],
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory,
    deps: [ConfigService],
  },
  MsalService,
  MsalBroadcastService
];
const InternalProviders: never[] = [];
const InternalBootstrap = [InternalAppComponent];
const AzureBootstrap = [ExternalAppComponent, MsalRedirectComponent];
const AuthProviders = environment.internal ? InternalProviders : AzureProviders;
const Bootstrappers = environment.internal ? InternalBootstrap : AzureBootstrap;

@NgModule({
  declarations: [
    InternalAppComponent,
    ExternalAppComponent,
    HomeComponent,
    AllComponent,
    MyFavorites,
    AirComponent,
    LandComponent,
    SeaComponent,
    SpaceComponent,
    JointComponent,
    OperationComponent,
    ProductListComponent,
    SortableIconsComponent,
    ProductDetailsDialogComponent,
    ProductDetailsComponent,
    MetadataComponent,
    TableComponent,
    TableTimeSliceComponent,
    LogoComponent,
    NotFoundComponent,
    ErrorComponent,
    ProductSearchPipe,
    ContactComponent,
    ImageSeriesDialogComponent,
    ImageSeriesPreviewComponent,
    ImageSeriesComponent,
    LatitudeDirection,
    LongitudeDirection,
  ],
  entryComponents: [
    ProductDetailsDialogComponent,
    ImageSeriesDialogComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    HeatMap,
    GraphComponent,
    DoubleGraphComponent,
    BrowserAnimationsModule,
    MatDialogModule,
    MatSliderModule,
    RouterModule.forRoot([
      { path: '', redirectTo: '/home', pathMatch: 'full', data: { animation: 'Home' } },
      { path: 'home', component: HomeComponent, pathMatch: 'full', data: { animation: 'Home' } },
      { path: 'all', redirectTo: 'all/', pathMatch: 'full' },
      { path: 'all/:id', component: AllComponent, data: { animation: 'All' } },
      { path: 'myFavorites', redirectTo: 'myFavorites/', pathMatch: 'full' },
      { path: 'myFavorites/:id', component: MyFavorites, data: { animation: 'Favourites' } },
      { path: 'air', redirectTo: 'air/', pathMatch: 'full' },
      { path: 'air/:id', component: AirComponent, data: { animation: 'Air' } },
      { path: 'land', redirectTo: 'land/', pathMatch: 'full' },
      { path: 'land/:id', component: LandComponent, data: { animation: 'Land' } },
      { path: 'sea', redirectTo: 'sea/', pathMatch: 'full' },
      { path: 'sea/:id', component: SeaComponent, data: { animation: 'Sea' } },
      { path: 'space', redirectTo: 'space/', pathMatch: 'full' },
      { path: 'space/:id', component: SpaceComponent, data: { animation: 'Space' } },
      { path: 'joint', redirectTo: 'joint/', pathMatch: 'full' },
      { path: 'joint/:id', component: JointComponent, data: { animation: 'Joint' } },
      { path: 'operation', redirectTo: 'operation/', pathMatch: 'full' },
      { path: 'operation/:id', component: OperationComponent, data: { animation: 'Operation' } },
      { path: 'contact', component: ContactComponent, data: { animation: 'Contact' } },
      { path: 'error', component: ErrorComponent, data: { animation: 'Error' } },
      { path: '404', component: NotFoundComponent, data: { animation: '404' } },
      { path: '**', redirectTo: '/404' }
    ]),
    TranslocoRootModule,
    ReactiveFormsModule
  ],
  providers: [
    { provide: Window, useValue: window },
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    LoggingService,
    { provide: APP_INITIALIZER, useFactory: initializeLogging, deps: [LoggingService], multi: true },
    AuthProviders
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: Bootstrappers
})
export class AppModule { }
