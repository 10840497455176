
<section class="product-container">
  <div class="products-list" [class.details-is-expanded]="selectedProduct != null">
    <div class="c-input -checkbox">
      <label>
        <input #onlyLowBandwidthCheckBox
               id="onlyLowBandwidthCheckBox"
               type="checkbox"
               [checked]="onlyLowBandwidth"
               (change)="onOnlyLowBandwidthChanged(onlyLowBandwidthCheckBox.checked)" />
        <span style="padding-right: 4px;">{{ "searchProductOnlyLowBandwidthText" | transloco }}</span>
      </label>
    </div>
    <div class="row search-area">
      <div class="-half">
        <label>{{ "searchProductLabelText" | transloco }}</label>
        <input name="searchInput" class="m-input" [(ngModel)]="searchInput" type="text" [placeholder]='"searchProductLabelText" | transloco'>
      </div>
      <div *ngIf="showOperationsFilter" class="operation-select -half">
        <label>{{ "chooseOperationLabelText" | transloco }}</label>
        <select class="m-select" (change)="operationSelected($event)">
          <option value="">{{ "chooseOperationDropDownText" | transloco }}</option>
          <option *ngFor="let operation of operations" [value]="operation">{{operation}}</option>
        </select>
      </div>
    </div>
    <table class="products-table">
      <tr class="product-headers">
        <th class="product-title sortable" (click)="sort('name')">
          {{ "product.name" | transloco }}
          <sortable-icons [sortActive]="sortColumn === 'name'" [sortOrder]="sortOrder" />
        </th>
        <th *ngIf="showOperationsFilter" class="product-operation sortable" (click)="sort('operation')">
          {{ "product.operation" | transloco }}
          <sortable-icons [sortActive]="sortColumn === 'operation'" [sortOrder]="sortOrder" />
        </th>
        <th class="product-place sortable" (click)="sort('place')">
          {{ "product.place" | transloco }}
          <sortable-icons [sortActive]="sortColumn === 'place'" [sortOrder]="sortOrder" />
        </th>
        <th class="product-updated sortable" (click)="sort('latestUpdatedTicks')">
          {{ "product.lastUpdated" | transloco }}
          <sortable-icons [sortActive]="sortColumn === 'latestUpdatedTicks'" [sortOrder]="sortOrder" />
        </th>
        <th class="product-isupdated"></th>
        <th class="product-bandwidth"></th>
        <th class="product-favourite"></th>
      </tr>
      <tr class="product-item" *ngFor="let product of fetchedProducts | productSearch: searchInput:selectedOperation:onlyLowBandwidth:sortOrder:sortColumn" (click)="onSelect(product)" [class.selected]="product === selectedProduct" [routerLink]="[currentRoute + '/' + product.id]">
        <td class="product-title">{{product.name}}</td>
        <td *ngIf="showOperationsFilter" class="product-operation">{{product.operation}}</td>
        <td class="product-place">{{product.place}}</td>
        <td class="product-updated">{{product.latestUpdateWithTime}}</td>
        <td class="product-isupdated">
          <svg *ngIf="!product.isUpdated" viewBox="0 0 20 20" id="i-time" class="icon svg-i-time" style="fill: orange">
            <path d="M14.5 10.5a.5.5 0 01-.5.5H9.5a.5.5 0 01-.5-.5v-6a.5.5 0 011 0V10h4a.5.5 0 01.5.5zM20 10c0 5.514-4.486 10-10 10S0 15.514 0 10 4.486 0 10 0s10 4.486 10 10zm-1 0c0-4.962-4.037-9-9-9s-9 4.038-9 9c0 4.963 4.037 9 9 9s9-4.037 9-9z" />
          </svg>
        </td>
        <td class="product-bandwidth">
          <svg *ngIf="product.isLowBandwidthProduct" viewBox="0 0 12 20" id="i-device" class="icon svg-i-device">
            <path d="M11.5 20H.5a.5.5 0 01-.5-.5V.5A.5.5 0 01.5 0h11a.5.5 0 01.5.5v19a.5.5 0 01-.5.5zM1 19h10V1H1v18zm6-1.5a.5.5 0 00-.5-.5h-1a.5.5 0 000 1h1a.5.5 0 00.5-.5z" />
          </svg>
        </td>
        <td class="product-favourite" (click)="$event.stopPropagation();switchFavouriteStatus(product)">
          <svg *ngIf="!product.isFavourite" class="icon svg-i-star" viewBox="0 0 20 19.967" id="i-star">
            <path d="M16.5 19.967a.494.494 0 01-.295-.097L10 15.336 3.795 19.87a.498.498 0 01-.759-.589l2.857-7.145L.2 7.867a.5.5 0 01.3-.9h6.647L9.529.299c.143-.398.799-.398.941 0l2.382 6.668H19.5a.5.5 0 01.3.9l-5.693 4.27 2.857 7.145a.499.499 0 01-.464.685zm-6.5-5.75c.104 0 .207.032.295.097l5.094 3.722-2.353-5.883a.5.5 0 01.164-.586l4.8-3.6h-5.5a.502.502 0 01-.471-.332L10 1.954 7.971 7.635a.502.502 0 01-.471.332H2l4.8 3.6a.5.5 0 01.164.586l-2.353 5.883 5.094-3.722a.496.496 0 01.295-.097z" />
          </svg>
          <svg *ngIf="product.isFavourite" class="icon svg-i-star-filled" viewBox="0 0 20 19.967" id="i-star-filled">
            <path d="M19.8 7.867l-5.693 4.27 2.857 7.145a.499.499 0 01-.759.588L10 15.336 3.795 19.87a.498.498 0 01-.759-.589l2.857-7.145L.2 7.867a.5.5 0 01.3-.9h6.647L9.529.299c.143-.398.799-.398.941 0l2.382 6.668H19.5a.5.5 0 01.3.9z" />
          </svg>
        </td>
      </tr>
    </table>
  </div>

  <app-product-details [product]="selectedProduct" (productClose)="productClosed()" class="product-details" [class.is-expanded]="selectedProduct != null" [routerLink]="[currentRoute]"></app-product-details>
</section>
