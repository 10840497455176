import { Injectable } from '@angular/core';
import anime from 'animejs/lib/anime.es.js';

@Injectable({
  providedIn: 'root',
})
export class AnimationHelper {
  constructor() { }

  animateHamburgerMenuItems(mainNavItems: any, isOpen:boolean) {
    if (!mainNavItems) return;

    anime.remove([mainNavItems])
    const tl = anime.timeline()
    if (isOpen) {
      tl
        .add({
          targets: mainNavItems,
          translateX: ['-100%', 0],
          opacity: [0, 1],
          easing: 'easeOutElastic( 1, 0.7 )',
          duration: 800,
          delay: anime.stagger(50, { start: 200 })
        });
    } else {
      anime({
        targets: mainNavItems,
        opacity: 0,
        translateX: '-100%',
        easing: 'easeInOutCubic',
        duration: 200
      })
    }
  }
}
