import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataStore } from "./data.store";
import { slideInAnimation } from './route-animation';
import { AnimationHelper } from './animation.helper';
import { TranslocoService } from '@ngneat/transloco';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus, EventMessage, EventType } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { AppBaseComponent } from './app.base.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root-external',
  templateUrl: './app.component.html',
  animations: [slideInAnimation]
})
export class ExternalAppComponent extends AppBaseComponent implements OnInit, OnDestroy {
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    dataStore: DataStore,
    animationHelper: AnimationHelper,
    translocoService: TranslocoService) {
    super(dataStore, animationHelper, translocoService, true);
    this.isAuthenticated = false;
  }

  ngOnInit(): void {
    this.loadLanguage();
    this.setTouchAndBrowser();
    this.configureB2CAuth();
  }

  private configureB2CAuth() {
    this.isIframe = window !== window.parent && !window.opener;
    this.setLoginDisplay();
    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED)
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
          this.dataStore.initializeUserContext();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        if (this.authService.instance.getAllAccounts().length === 0) {
          this.authService.loginRedirect();
        } else {
          this.isAuthenticated = true;
          this.dataStore.initializeUserContext();
        }
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect() {
    this.authService.loginRedirect();
  }

  logout() {
    this.authService.logoutRedirect();
  }

  ngAfterViewInit() {
    //cookie
    const cookies = this.readFromStorage('fmvwkakor');

    if (cookies !== 'true' && this.fmCookieBannerRef)
      this.fmCookieBannerRef.nativeElement.style = "transform: none";
    else if (this.fmCookieBannerRef)
      this.fmCookieBannerRef.nativeElement.style = "transform: translateY(300%)";
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}

