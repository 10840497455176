import { DataService } from "../data.service";
import { Config } from '../config';
import { ILogger } from './ilogger';

export class WindowsLoggingService implements ILogger {
  constructor(config: Config, private dataService: DataService) {
    console.log('Windows logging configured.');
  }

  logPageView(name?: string, url?: string) { // option to call manually
    console.log(name + " " + url);
  }
  logEvent(name: string, properties?: { [key: string]: any }) {
    console.log(name, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    console.log(name, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.dataService.logException(exception)
      .subscribe((response: boolean) => {
        console.log('logged to backend: ' + response)
      });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    console.trace(message, properties);
  }
}
