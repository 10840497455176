import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DataService } from '../data.service';
import { ImageHelper } from '../image.helper';
import { TranslatedProduct } from '../model/translated-product';


@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html'
})
export class ProductDetailsComponent implements OnChanges {
  imageData: any;
  fullScreenOpen = false;
  @Input() product: TranslatedProduct | null;

  @Output() productClose = new EventEmitter();

  close() {
    this.product = null;
    this.fullScreenOpen = false;
    this.productClose.emit();
  }

  switchFullScreen() {
    this.fullScreenOpen = !this.fullScreenOpen;
  }

  constructor(private dataService: DataService, private imageHelper: ImageHelper) {
  }

  ngOnChanges() {
    if (this.product) {
      if (this.product?.productTypeId === 101) {
        this.dataService.getImage(this.product.files[0], this.product.id)
          .subscribe(data => {
            this.imageData = this.imageHelper.getImageData(this.product?.files[0], data);
          });
      }
    }
  }
}
