<div [class.is-active]="product != null" *ngIf="product">
  <header class="fm-dialog-header">
    <p mat-dialog-title>{{product!.name}}</p>
    <button (click)="close()" [routerLink]="[closeRoute]" class="dialog__close"><svg class="svg-i-close" viewBox="0 0 512 512" id="i-close"><path d="M274.329 255.503l135.146-135.146-19.316-19.317-135.145 135.146L119.869 101.04l-19.317 19.317 135.146 135.146-135.146 135.145 19.317 19.316 135.145-135.146zm19.297 19.286l115.83 115.83-19.304 19.306L274.32 294.094z"></path></svg></button>
  </header>
  <app-product-metadata [product]="product"></app-product-metadata>
  <div mat-dialog-content class="dialog__message--large dialog__message--active selected-product-dialog-content">
    <div *ngIf="product.productTypeId >= 300 && product.productTypeId < 303">
      <app-graph [product]="product"></app-graph>
    </div>
    <div *ngIf="product.productTypeId === 303">
      <app-heat-map [product]="product"> </app-heat-map>
    </div>
    <div *ngIf="product.productTypeId === 400 || product.productTypeId === 401 || product.productTypeId === 402">
      <app-table [product]="product"></app-table>
    </div>
    <div *ngIf="product.productTypeId === 101" class="x-scroll">
      <img src="products/{{ product.id }}/{{ product.files[0] }}" />
    </div>
    <div *ngIf="product.productTypeId === 404">
      <app-table-timeslice [product]="product"></app-table-timeslice>
    </div>
    <div *ngIf="product.productTypeId === 200">
      <app-image-series [product]="product"></app-image-series>
    </div>
  </div>
  <div mat-dialog-actions>
  </div>
</div>
