import { Component } from '@angular/core';
import { Observable } from "rxjs";
import { DataStore } from '../data.store';
import { LoggingService } from '../logging/logging.service';
import { TranslatedProduct } from '../model/translated-product';

@Component({
  selector: 'air',
  templateUrl: './air.component.html',
})
export class AirComponent {
  products: Observable<Array<TranslatedProduct>>;
  airheader: string;
  airText: string;

  constructor(private dataStore: DataStore, private loggingService: LoggingService) {
    this.products = this.dataStore.airProducts;

    this.dataStore.airPageHeader.subscribe(res => {
      this.airheader = res;
    }, error => console.error(error));

    this.dataStore.airPageText.subscribe(res => {
      this.airText = res;
    }, error => console.error(error));
  }  
}
