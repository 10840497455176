import { Component } from '@angular/core';
import { Observable } from "rxjs";
import { DataStore } from '../data.store';
import { LoggingService } from '../logging/logging.service';
import { TranslatedProduct } from '../model/translated-product';

@Component({
  selector: 'land',
  templateUrl: './land.component.html',
})
export class LandComponent {
  products: Observable<Array<TranslatedProduct>>;
  landHeader: string;
  landText: string;

  constructor(private dataStore: DataStore, private loggingService: LoggingService) {
    this.products = this.dataStore.landProducts;

    this.dataStore.landPageHeader.subscribe(res => {
      this.landHeader = res;
    }, error => console.error(error));

    this.dataStore.landPageText.subscribe(res => {
      this.landText = res;
    }, error => console.error(error));
  }
}
