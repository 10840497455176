import { Component, Input } from '@angular/core';
import { TranslatedProduct } from '../model/translated-product';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ImageSeriesDialogComponent } from './image-series-dialog.component';
import { DataService } from '../data.service';
import { ImageHelper } from '../image.helper';

@Component({
  selector: 'app-image-series-preview',
  templateUrl: './image-series-preview.component.html'
})

export class ImageSeriesPreviewComponent {
  @Input() product: TranslatedProduct;
  imageData: any;

  constructor(private matDialog: MatDialog, private dataService: DataService, private imageHelper: ImageHelper) { }
  productId: number;

  ngOnChanges() {
    if (this.product) {
      this.productId = this.product.id
      this.dataService.getImage(this.product.files[0], this.product.id)
        .subscribe(data => {
          this.imageData = this.imageHelper.getImageData(this.product?.files[0], data);
        });
    }
  }

  openDialog() {
    if (this.matDialog) {
      this.matDialog.closeAll();
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.product;
    dialogConfig.panelClass = "fm-full-screen-dialog-container";
    const dialogRef = this.matDialog.open(ImageSeriesDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(value => {

    });
  }

}
