import { Injectable } from '@angular/core';
import { LoggingService } from './logging.service';
import { Config } from '../config';
import { ILogger } from './ilogger';

export class ConsoleLoggingService implements ILogger {
  constructor(config: Config) {
    console.log('Console logging configured.');    
  }
  
  logPageView(name?: string, url?: string) { // option to call manually
    console.log(name + " " + url);
  }
  logEvent(name: string, properties?: { [key: string]: any }) {
    console.log(name, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    console.log(name, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    console.error(exception.message);
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    console.trace(message, properties);
  }
}
