import { Component, Input, OnInit, HostListener } from '@angular/core';
import { DataStore } from "../data.store";
import { DataService } from "../data.service";
import { Observable } from "rxjs";
import { TranslatedProduct } from '../model/translated-product';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProductDetailsDialogComponent } from "../product-details/product-details-dialog.component";

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
})
export class ProductListComponent implements OnInit {
  fetchedProducts: TranslatedProduct[];
  @Input() products: Observable<TranslatedProduct[]>;
  @Input() showOperationsFilter: boolean;
  selectedProduct: TranslatedProduct | null;
  currentRoute: string | undefined;
  onlyLowBandwidth = false;
  public searchInput = '';
  public operations: string[] = [];
  public selectedOperation = '';

  public sortOrder = 'asc';
  public sortColumn = ''

  constructor(private matDialog: MatDialog, private activatedRoute: ActivatedRoute, private router: Router, private dataStore: DataStore, private dataService: DataService) {

  }
  public screenWidth: any;

  ngOnInit() {
    this.screenWidth = window.innerWidth;

    this.products.subscribe(result => {

      this.fetchedProducts = result.filter(item => item.isActive == true);

      if (this.showOperationsFilter)
        this.operations = [...new Set(this.fetchedProducts.map(item => item.operation))];

      const productIdParameter = this.activatedRoute.snapshot.params.id;

      if (productIdParameter) {

        this.currentRoute = this.router.url.replace('/' + productIdParameter, '');
        let selected = this.fetchedProducts.find(f => f.id === parseInt(productIdParameter));

        if (selected) {
          this.onSelect(selected);
        }
      }
      else {
        this.currentRoute = this.router.url;
      }
    });
  }

  @HostListener('window:resize', ['$event'])

  onResize(event: any) {
    this.screenWidth = window.innerWidth;
    if (this.selectedProduct != null && this.screenWidth < 900)
      this.selectedProduct = null;
    else if (this.matDialog && this.screenWidth > 900)
      this.matDialog.closeAll();
  }

  onSelect(product: TranslatedProduct) {
    //log click if of type x - refactor once we know more regarding product types
    if (product.productTypeId >= 300 || product.productTypeId === 101) {
      this.dataService.productClicked(product.id).subscribe(result => {
      });
    }

    if (this.screenWidth < 900)
      this.openDialog(product);
    else
      this.selectedProduct = product;
  }

  sort(value: string) {
    if (value === this.sortColumn) {
      if (this.sortOrder === "desc")
        this.sortOrder = "asc";
      else
        this.sortOrder = "desc"
    } else {
      this.sortColumn = value;
    }
  }

  onOnlyLowBandwidthChanged(value: boolean) {
    this.onlyLowBandwidth = value;
  }

  productClosed() {
    this.selectedProduct = null;
  }

  switchFavouriteStatus(product: TranslatedProduct) {
    product.isFavourite = !product.isFavourite;
    if (product.isFavourite)
      this.dataStore.createFavourite(product);
    else
      this.dataStore.deleteFavourite(product.id);
  }

  operationSelected(e: any) {
    this.selectedOperation = e.target.value;
  }

  openDialog(product: TranslatedProduct) {
    if (this.matDialog)
      this.matDialog.closeAll();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = product;
    dialogConfig.panelClass = "fm-full-screen-dialog-container";
    const dialogRef = this.matDialog.open(ProductDetailsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(value => {
    });
  }
}
