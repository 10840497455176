export class Constants {
  static readonly start_page_header = "start_page_header";
  static readonly start_page_intro_text = "start_page_intro_text";
  static readonly all_page_header = "all_page_header";
  static readonly all_page_text = "all_page_text";
  static readonly favorites_page_header = "favorites_page_header";
  static readonly favorites_page_text = "favorites_page_text";
  static readonly air_page_header = "air_page_header";
  static readonly air_page_text = "air_page_text";
  static readonly land_page_header = "land_page_header";
  static readonly land_page_text = "land_page_text";
  static readonly sea_page_header = "sea_page_header";
  static readonly sea_page_text = "sea_page_text";
  static readonly space_page_header = "space_page_header";
  static readonly space_page_text = "space_page_text";
  static readonly joint_page_header = "joint_page_header";
  static readonly joint_page_text = "joint_page_text";
  static readonly operation_page_header = "operation_page_header";
  static readonly operation_page_text = "operation_page_text";
  static readonly contact_page_header = "contact_page_header";
  static readonly contact_page_text = "contact_page_text";
  static readonly contact_missing_header = "contact_missing_header";
  static readonly contact_missing_text = "contact_missing_text";
  static readonly contact_link_text = "contact_link_text";
  static readonly contact_link_link = "contact_link_link";


  static readonly area_id_land = 1;
  static readonly area_id_air = 2;
  static readonly area_id_sea = 3;
  static readonly area_id_space = 4;
  static readonly area_id_joint = 5;

}
