import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ConfigService } from '../config.service';
import { DataStore } from '../data.store';

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html' 
})
export class ContactComponent {
  formGroup: FormGroup;
  public mailtoLink: string;
  public isInternalDeployment: string;
  public orderLink: string;
  public contactHeader: string;
  public contactText: string;
  public contactMissingHeader: string;
  public contactMissingText: string;
  public contactLinkText: string;
  public contactLinkLink: string;

  constructor(private formBuilder: FormBuilder, public configService: ConfigService, private dataStore: DataStore,) {    
    this.isInternalDeployment = configService.config.isInternalDeployment;
    this.orderLink = configService.config.orderLink;

    this.dataStore.contactPageHeader.subscribe(res => {
      this.contactHeader = res;
    }, error => console.error(error));

    this.dataStore.contactPageText.subscribe(res => {
      this.contactText = res;
    }, error => console.error(error));

    this.dataStore.contactMissingHeader.subscribe(res => {
      this.contactMissingHeader = res;
    }, error => console.error(error));

    this.dataStore.contactMissingText.subscribe(res => {
      this.contactMissingText = res;
    }, error => console.error(error));

    this.dataStore.contactLinkText.subscribe(res => {
      this.contactLinkText = res;
    }, error => console.error(error));

    this.dataStore.contactLinkLink.subscribe(res => {
      this.contactLinkLink = res;
    }, error => console.error(error));
  }

  public regex = {
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    text: /^[^<>\x22{}']+$/,
    zipcode: /^\d{5}$/,
    phone: /^\+?\d{7,15}$/
  }

  get name() {
    return this.formGroup.get('name') as FormControl
  }
  get unit() {
    return this.formGroup.get('unit') as FormControl
  }
  get phone() {
    return this.formGroup.get('phone') as FormControl
  }
  get matter() {
    return this.formGroup.get('matter') as FormControl
  }

  ngOnInit() {
      this.formGroup = this.formBuilder.group({
        name: new FormControl(''),
        unit: new FormControl('', [Validators.required, Validators.pattern(this.regex.text)]),
        phone: new FormControl('', [Validators.required, Validators.pattern(this.regex.phone)]),
        matter: new FormControl('', [Validators.required, Validators.pattern(this.regex.text)])
      });
  }

  onSubmit() {
    this.mailtoLink = 'mailto:' + this.configService.config.mailtoAddress + '?Subject=Ärende' + '&Body=Namn:%20%0D%0A' + this.name.value + '%0D%0A%0D%0A' + 'Förband/skola:%0D%0A' + this.unit.value
      + '%0D%0A%0D%0A' + 'Telefon:%0D%0A' + this.phone.value + '%0D%0A%0D%0A' + 'Ärende:%0D%0A' + this.matter.value;
    window.location.href = this.mailtoLink
  }
}
