<div class="selected-product" [class.is-active]="product != null" [class.product-full-screen]="fullScreenOpen" *ngIf="product">
  <div class="selected-product-inner">
    <div style="padding:5px;">
      <button (click)="close()" class="dialog__close"><svg class="svg-i-close close-selected" viewBox="0 0 512 512" id="i-close"><path d="M274.329 255.503l135.146-135.146-19.316-19.317-135.145 135.146L119.869 101.04l-19.317 19.317 135.146 135.146-135.146 135.145 19.317 19.316 135.145-135.146zm19.297 19.286l115.83 115.83-19.304 19.306L274.32 294.094z"></path></svg></button>
    </div>
    <div class="selected-product-content">
      <div style="display:flex;">
        <h3 class="selected-product-header">{{ product.name | uppercase }} - {{product.place}}</h3>
        <button style="height:17px;margin-top:4px" *ngIf="!fullScreenOpen" title="{{ 'product.openFullscreen' | transloco }}" (click)="switchFullScreen()" class="dialog__fullScreen"><svg class="svg-i-maximize maximize-selected" viewBox="0 0 20 20" id="i-maximize"><path stroke-width="100" d="M7.854 7.146a.5.5 0 01-.708.708L1 1.707V4.5a.5.5 0 01-1 0v-4A.5.5 0 01.5 0h4a.5.5 0 010 1H1.707l6.147 6.146zm-.708 5L1 18.293V15.5a.5.5 0 00-1 0v4a.5.5 0 00.5.5h4a.5.5 0 000-1H1.707l6.146-6.146a.5.5 0 00-.707-.708zM19.5 0h-4a.5.5 0 000 1h2.793l-6.146 6.146a.5.5 0 00.707.708L19 1.707V4.5a.5.5 0 001 0v-4a.5.5 0 00-.5-.5zm0 15a.5.5 0 00-.5.5v2.793l-6.146-6.146a.5.5 0 00-.707.707L18.293 19H15.5a.5.5 0 000 1h4a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5z"></path></svg></button>
        <button  style="height:17px;margin-top:4px" *ngIf="fullScreenOpen" title="{{ 'product.closeFullscreen' | transloco }}" (click)="switchFullScreen()" class="dialog__fullScreen"><svg class="svg-i-maximize maximize-selected" viewBox="0 0 20 20" id="i-maximize"><path stroke-width="100" d="M8 3.5v4a.5.5 0 01-.5.5h-4a.5.5 0 010-1h2.793L.146.854A.5.5 0 01.853.147L7 6.293V3.5a.5.5 0 011 0zM7.5 12h-4a.5.5 0 000 1h2.793L.147 19.146a.5.5 0 00.707.708L7 13.707V16.5a.5.5 0 001 0v-4a.5.5 0 00-.5-.5zm5-4h4a.5.5 0 000-1h-2.793L19.853.854a.5.5 0 00-.707-.707L13 6.293V3.5a.5.5 0 00-1 0v4a.5.5 0 00.5.5zm1.207 5H16.5a.5.5 0 000-1h-4a.5.5 0 00-.5.5v4a.5.5 0 001 0v-2.793l6.146 6.146a.5.5 0 10.708-.706L13.707 13z" /></svg></button>
      </div>
      <app-product-metadata [product]="product"></app-product-metadata>
      <div *ngIf="product.productTypeId >= 300 && product.productTypeId < 303">
        <app-graph [product]="product" [reRender]="fullScreenOpen"></app-graph>
      </div>
      <div *ngIf="product.productTypeId === 303">
        <app-heat-map [product]="product" [reRender]="fullScreenOpen"> </app-heat-map>
      </div>
      <div *ngIf="product.productTypeId === 400 || product.productTypeId === 401 || product.productTypeId === 402">
        <app-table [product]="product"></app-table>
      </div>
      <div *ngIf="product.productTypeId === 404">
        <app-table-timeslice [product]="product"></app-table-timeslice>
      </div>
      <div *ngIf="product.productTypeId === 101" class="x-scroll">
        <img [src]="imageData" />
      </div>
      <div *ngIf="product.productTypeId === 200">
        <app-image-series-preview [product]="product"> </app-image-series-preview>
      </div>
    </div>
  </div>
</div>
