import { Component, Input } from '@angular/core';

@Component({
  selector: 'sortable-icons',
  templateUrl: './sortable-icons.component.html',
})
export class SortableIconsComponent {
  @Input() sortActive: boolean;
  @Input() sortOrder: string;

  constructor() {
  }
}
