import { Component, Input, OnChanges } from '@angular/core';
import { TranslatedProduct } from '../model/translated-product';
import { DataService } from '../data.service';

@Component({
  selector: 'app-table-timeslice',
  templateUrl: './table-timeslice.component.html'
})

export class TableTimeSliceComponent implements OnChanges {
  @Input() product: TranslatedProduct;
  tableData: any;
  selectedItems: any;
  timeSlots: any[] = [];
  lat: any;
  lon: any;
  height: any;

  constructor(private dataService: DataService) {
  }

  ngOnChanges() {
    this.dataService.getJsonData('table_data.json', this.product.id)
      .subscribe(data => {
        this.tableData = data as any[];
        this.lat = this.tableData.lat;
        this.lon = this.tableData.lon;
        this.height = this.tableData.height;
        this.selectedItems = this.tableData.table;
        this.timeSlots = this.tableData.times;
      });
    //let ticks = (new Date()).getTime();
    //let parameters = new HttpParams();
    //parameters = parameters.append('reponseType', 'json');
    //this.http.get('products/' + this.product.id + '/table_data.json?' + ticks, { params: parameters }).subscribe(
    //  data => {
    //    this.tableData = data as any[];
    //    this.lat = this.tableData.lat;
    //    this.lon = this.tableData.lon;
    //    this.height = this.tableData.height;
    //    this.selectedItems = this.tableData.table;
    //    this.timeSlots = this.tableData.times;
    //  },
    //  (err: HttpErrorResponse) => {
    //    console.log(err.message);
    //  }
    //);
  }

  newTimeSelected(e: any) {
    console.log(e.target["selectedIndex"]);
    this.selectedItems = this.tableData.data[e.target["selectedIndex"]];
  }
}

