import { DataService } from "../data.service";
import { Config } from '../config';
import { Injectable } from '@angular/core';
import { ConsoleLoggingService } from './console-logging.service';
import { AILoggingService } from './ai-logging.service';
import { ILogger } from './ilogger';
import { WindowsLoggingService } from './windows-logging.service';

@Injectable()
export class LoggingService {
  logger: ILogger;

  constructor(private dataService: DataService) {
  }

  initLogger(config: Config) {
    if (config.logging == 'AI')
      this.logger = new AILoggingService(config);
    else if (config.logging == 'Windows')
      this.logger = new WindowsLoggingService(config, this.dataService);
    else
      this.logger = new ConsoleLoggingService(config);
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.logger.logPageView(name, url);
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.logger.logEvent(name, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.logger.logMetric(name, average, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.logger.logException(exception, severityLevel);
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.logger.logTrace(message, properties);
  }
}
