import { Injectable } from '@angular/core';
import { DataService } from "./data.service";
import { Observable } from "rxjs";
import { Subject } from "rxjs";
import { Constants } from './constants';
import { BehaviorSubject } from "rxjs";
import { Product } from './model/product';
import { ContentItem } from './model/contentItem';
import { TranslatedProduct } from './model/translated-product';
import { TranslocoService } from '@ngneat/transloco';
import { Favourite } from './model/favourite';
import { UserContext } from './model/userContext';

@Injectable({
  providedIn: 'root',
})

export class DataStore {
  private _userName: Subject<string> = new BehaviorSubject<string>("");
  public readonly userName: Observable<string> = this._userName.asObservable();

  private _allProducts: BehaviorSubject<Array<Product>> = new BehaviorSubject<Array<Product>>([]);

  private _allTranslatedProducts: Subject<Array<TranslatedProduct>> = new BehaviorSubject<Array<TranslatedProduct>>([]);
  public readonly allProducts: Observable<Array<TranslatedProduct>> = this._allTranslatedProducts.asObservable();

  private _myFavourites: BehaviorSubject<Array<TranslatedProduct>> = new BehaviorSubject<Array<TranslatedProduct>>([]);
  public readonly myFavourites: Observable<Array<TranslatedProduct>> = this._myFavourites.asObservable();

  private _favourites: BehaviorSubject<Array<Favourite>> = new BehaviorSubject<Array<Favourite>>([]);
  public readonly favourites: Observable<Array<Favourite>> = this._favourites.asObservable();

  private _airProducts: Subject<Array<TranslatedProduct>> = new BehaviorSubject<Array<TranslatedProduct>>([]);
  public readonly airProducts: Observable<Array<TranslatedProduct>> = this._airProducts.asObservable();

  private _landProducts: Subject<Array<TranslatedProduct>> = new BehaviorSubject<Array<TranslatedProduct>>([]);
  public readonly landProducts: Observable<Array<TranslatedProduct>> = this._landProducts.asObservable();

  private _seaProducts: Subject<Array<TranslatedProduct>> = new BehaviorSubject<Array<TranslatedProduct>>([]);
  public readonly seaProducts: Observable<Array<TranslatedProduct>> = this._seaProducts.asObservable();

  private _spaceProducts: Subject<Array<TranslatedProduct>> = new BehaviorSubject<Array<TranslatedProduct>>([]);
  public readonly spaceProducts: Observable<Array<TranslatedProduct>> = this._spaceProducts.asObservable();

  private _jointProducts: Subject<Array<TranslatedProduct>> = new BehaviorSubject<Array<TranslatedProduct>>([]);
  public readonly jointProducts: Observable<Array<TranslatedProduct>> = this._jointProducts.asObservable();

  private _operationProducts: Subject<Array<TranslatedProduct>> = new BehaviorSubject<Array<TranslatedProduct>>([]);
  public readonly operationProducts: Observable<Array<TranslatedProduct>> = this._operationProducts.asObservable();

  private _allContentItems: BehaviorSubject<Array<ContentItem>> = new BehaviorSubject<Array<ContentItem>>([]);

  private _translatedStartPageHeader: Subject<string> = new BehaviorSubject<string>("");
  public readonly startPageHeader: Observable<string> = this._translatedStartPageHeader.asObservable();

  private _translatedStartPageIntroText: Subject<string> = new BehaviorSubject<string>("");
  public readonly startPageIntroText: Observable<string> = this._translatedStartPageIntroText.asObservable();

  private _translatedAllPageHeader: Subject<string> = new BehaviorSubject<string>("");
  public readonly allPageHeader: Observable<string> = this._translatedAllPageHeader.asObservable();

  private _translatedAllPageText: Subject<string> = new BehaviorSubject<string>("");
  public readonly allPageText: Observable<string> = this._translatedAllPageText.asObservable();

  private _translatedFavoritesPageHeader: Subject<string> = new BehaviorSubject<string>("");
  public readonly favoritesPageHeader: Observable<string> = this._translatedFavoritesPageHeader.asObservable();

  private _translatedFavoritesPageText: Subject<string> = new BehaviorSubject<string>("");
  public readonly favoritesPageText: Observable<string> = this._translatedFavoritesPageText.asObservable();

  private _translatedAirPageHeader: Subject<string> = new BehaviorSubject<string>("");
  public readonly airPageHeader: Observable<string> = this._translatedAirPageHeader.asObservable();

  private _translatedAirPageText: Subject<string> = new BehaviorSubject<string>("");
  public readonly airPageText: Observable<string> = this._translatedAirPageText.asObservable();

  private _translatedLandPageHeader: Subject<string> = new BehaviorSubject<string>("");
  public readonly landPageHeader: Observable<string> = this._translatedLandPageHeader.asObservable();

  private _translatedLandPageText: Subject<string> = new BehaviorSubject<string>("");
  public readonly landPageText: Observable<string> = this._translatedLandPageText.asObservable();

  private _translatedSeaPageHeader: Subject<string> = new BehaviorSubject<string>("");
  public readonly seaPageHeader: Observable<string> = this._translatedSeaPageHeader.asObservable();

  private _translatedSeaPageText: Subject<string> = new BehaviorSubject<string>("");
  public readonly seaPageText: Observable<string> = this._translatedSeaPageText.asObservable();

  private _translatedSpacePageHeader: Subject<string> = new BehaviorSubject<string>("");
  public readonly spacePageHeader: Observable<string> = this._translatedSpacePageHeader.asObservable();

  private _translatedSpacePageText: Subject<string> = new BehaviorSubject<string>("");
  public readonly spacePageText: Observable<string> = this._translatedSpacePageText.asObservable();

  private _translatedJointPageHeader: Subject<string> = new BehaviorSubject<string>("");
  public readonly jointPageHeader: Observable<string> = this._translatedJointPageHeader.asObservable();

  private _translatedJointPageText: Subject<string> = new BehaviorSubject<string>("");
  public readonly jointPageText: Observable<string> = this._translatedJointPageText.asObservable();

  private _translatedOperationPageHeader: Subject<string> = new BehaviorSubject<string>("");
  public readonly operationPageHeader: Observable<string> = this._translatedOperationPageHeader.asObservable();

  private _translatedOperationPageText: Subject<string> = new BehaviorSubject<string>("");
  public readonly operationPageText: Observable<string> = this._translatedOperationPageText.asObservable();

  private _translatedContactPageHeader: Subject<string> = new BehaviorSubject<string>("");
  public readonly contactPageHeader: Observable<string> = this._translatedContactPageHeader.asObservable();

  private _translatedContactPageText: Subject<string> = new BehaviorSubject<string>("");
  public readonly contactPageText: Observable<string> = this._translatedContactPageText.asObservable();

  private _translatedContactMissingHeader: Subject<string> = new BehaviorSubject<string>("");
  public readonly contactMissingHeader: Observable<string> = this._translatedContactMissingHeader.asObservable();

  private _translatedContactMissingText: Subject<string> = new BehaviorSubject<string>("");
  public readonly contactMissingText: Observable<string> = this._translatedContactMissingText.asObservable();

  private _translatedContactLinkText: Subject<string> = new BehaviorSubject<string>("");
  public readonly contactLinkText: Observable<string> = this._translatedContactLinkText.asObservable();

  private _translatedContactLinkLink: Subject<string> = new BehaviorSubject<string>("");
  public readonly contactLinkLink: Observable<string> = this._translatedContactLinkLink.asObservable();

  public tempFav: Array<Product> = new Array<Product>;

  constructor(private dataService: DataService, private translocoService: TranslocoService) {
  }

  initializeUserContext() {
    this.dataService.getUserContext()
      .subscribe((response: UserContext) => {
        let products = response.products;
        let favourites = response.favourites;        
        if (favourites) {          
          for (let i = 0; i < favourites.length; i++) {
            let product = products.find(p => p.id === favourites[i].productId);
            if (product) {
              product.isFavourite = true;
              this.tempFav.push(product);
            }
          }
        }
        this._allProducts.next(products);
        this._allContentItems.next(response.contentItems);
        this._userName.next(response.userName);
        this.setLanguage();
      });
  }

  setLanguage() {
    const activeLanguage = this.translocoService.getActiveLang();

    let header = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.start_page_header })[0];
    this._translatedStartPageHeader.next(activeLanguage === 'sv' ? header.content.sv : header.content.en);

    let introText = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.start_page_intro_text })[0];
    this._translatedStartPageIntroText.next(activeLanguage === 'sv' ? introText.content.sv : introText.content.en);

    let allHeader = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.all_page_header })[0];
    this._translatedAllPageHeader.next(activeLanguage === 'sv' ? allHeader.content.sv : allHeader.content.en);

    let allText = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.all_page_text })[0];
    this._translatedAllPageText.next(activeLanguage === 'sv' ? allText.content.sv : allText.content.en);

    let favoritesHeader = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.favorites_page_header })[0];
    this._translatedFavoritesPageHeader.next(activeLanguage === 'sv' ? favoritesHeader.content.sv : favoritesHeader.content.en);

    let favoritesText = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.favorites_page_text })[0];
    this._translatedFavoritesPageText.next(activeLanguage === 'sv' ? favoritesText.content.sv : favoritesText.content.en);

    let airHeader = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.air_page_header })[0];
    this._translatedAirPageHeader.next(activeLanguage === 'sv' ? airHeader.content.sv : airHeader.content.en);

    let airText = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.air_page_text })[0];
    this._translatedAirPageText.next(activeLanguage === 'sv' ? airText.content.sv : airText.content.en);

    let landHeader = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.land_page_header })[0];
    this._translatedLandPageHeader.next(activeLanguage === 'sv' ? landHeader.content.sv : landHeader.content.en);

    let landText = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.land_page_text })[0];
    this._translatedLandPageText.next(activeLanguage === 'sv' ? landText.content.sv : landText.content.en);

    let seaHeader = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.sea_page_header })[0];
    this._translatedSeaPageHeader.next(activeLanguage === 'sv' ? seaHeader.content.sv : seaHeader.content.en);

    let seaText = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.sea_page_text })[0];
    this._translatedSeaPageText.next(activeLanguage === 'sv' ? seaText.content.sv : seaText.content.en);

    let spaceHeader = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.space_page_header })[0];
    this._translatedSpacePageHeader.next(activeLanguage === 'sv' ? spaceHeader.content.sv : spaceHeader.content.en);

    let spaceText = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.space_page_text })[0];
    this._translatedSpacePageText.next(activeLanguage === 'sv' ? spaceText.content.sv : spaceText.content.en);

    let jointHeader = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.joint_page_header })[0];
    this._translatedJointPageHeader.next(activeLanguage === 'sv' ? jointHeader.content.sv : jointHeader.content.en);

    let jointText = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.joint_page_text })[0];
    this._translatedJointPageText.next(activeLanguage === 'sv' ? jointText.content.sv : jointText.content.en);

    let operationHeader = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.operation_page_header })[0];
    this._translatedOperationPageHeader.next(activeLanguage === 'sv' ? operationHeader.content.sv : operationHeader.content.en);

    let operationText = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.operation_page_text })[0];
    this._translatedOperationPageText.next(activeLanguage === 'sv' ? operationText.content.sv : operationText.content.en);

    let contactHeader = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.contact_page_header })[0];
    this._translatedContactPageHeader.next(activeLanguage === 'sv' ? contactHeader.content.sv : contactHeader.content.en);

    let contactText = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.contact_page_text })[0];
    this._translatedContactPageText.next(activeLanguage === 'sv' ? contactText.content.sv : contactText.content.en);

    let contactMissingHeader = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.contact_missing_header })[0];
    this._translatedContactMissingHeader.next(activeLanguage === 'sv' ? contactMissingHeader.content.sv : contactMissingHeader.content.en);

    let contactMissingText = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.contact_missing_text })[0];
    this._translatedContactMissingText.next(activeLanguage === 'sv' ? contactMissingText.content.sv : contactMissingText.content.en);

    let contactLinkText = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.contact_link_text })[0];
    this._translatedContactLinkText.next(activeLanguage === 'sv' ? contactLinkText.content.sv : contactLinkText.content.en);

    let contactLinkLink = this._allContentItems.getValue().filter((obj) => { return obj.key === Constants.contact_link_link })[0];
    this._translatedContactLinkLink.next(activeLanguage === 'sv' ? contactLinkLink.content.sv : contactLinkLink.content.en);

    this._allTranslatedProducts.next(this._allProducts.getValue().map(p => new TranslatedProduct(p, activeLanguage)));
    this._myFavourites.next(this.tempFav.map(f => new TranslatedProduct(f, activeLanguage)));    
    this._landProducts.next(this._allProducts.getValue().filter((obj) => { return obj.arenaId === Constants.area_id_land }).map(p => new TranslatedProduct(p, activeLanguage)));
    this._airProducts.next(this._allProducts.getValue().filter((obj) => { return obj.arenaId === Constants.area_id_air }).map(p => new TranslatedProduct(p, activeLanguage)));
    this._seaProducts.next(this._allProducts.getValue().filter((obj) => { return obj.arenaId === Constants.area_id_sea }).map(p => new TranslatedProduct(p, activeLanguage)));
    this._spaceProducts.next(this._allProducts.getValue().filter((obj) => { return obj.arenaId === Constants.area_id_space }).map(p => new TranslatedProduct(p, activeLanguage)));
    this._jointProducts.next(this._allProducts.getValue().filter((obj) => { return obj.arenaId === Constants.area_id_joint }).map(p => new TranslatedProduct(p, activeLanguage)));
    this._operationProducts.next(this._allProducts.getValue().filter((obj) => { return obj.operation !== '' }).map(p => new TranslatedProduct(p, activeLanguage)));
  }

  deleteFavourite(productId: number) {
    this.dataService.deleteFavourite(productId)
      .subscribe((response: boolean) => {
        if (response) {
          let productToChange = this._allProducts.getValue().find(p => p.id == productId);
          if (productToChange) {
            productToChange.isFavourite = false;

            let newTempFavorites = this.tempFav.filter(function (obj) {
              return obj.id !== productId;
            });            
            this.tempFav = newTempFavorites;
            this.setLanguage();
          }
        } else {
          //TODO: Manage?
        }
      });
  }

  selectFavourite(productId: number) {
    this.dataService.favouriteClicked(productId)
      .subscribe((response: boolean) => {
        this.setLanguage();      
      });
  }

  createFavourite(product: TranslatedProduct) {
    this.dataService.createFavourite(product.id)
      .subscribe((response: number) => {
        if (response > 0) {
          let productToChange = this._allProducts.getValue().find(p => p.id == product.id);
          if (productToChange) {
            this.tempFav.push(productToChange);
            productToChange.isFavourite = product.isFavourite;
            this.setLanguage();
          }
        } else {
         //TODO: Manage?
         }
      });
  }
}
