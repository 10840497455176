import { Component } from '@angular/core';
import { DataStore } from '../data.store';
import { Observable } from "rxjs";
import { LoggingService } from '../logging/logging.service';
import { TranslatedProduct } from '../model/translated-product';

@Component({
  selector: 'myFavorites',
  templateUrl: './myFavorites.component.html',
})
export class MyFavorites {
  products: Observable<Array<TranslatedProduct>>;
  favoritesHeader: string;
  favoritesText: string;

  constructor(private dataStore: DataStore, private loggingService: LoggingService) {
    this.products = this.dataStore.myFavourites;

    this.dataStore.favoritesPageHeader.subscribe(res => {
      this.favoritesHeader = res;
    }, error => console.error(error));

    this.dataStore.favoritesPageText.subscribe(res => {
      this.favoritesText = res;
    }, error => console.error(error));
  }
}
