import { Component, Inject, Input, OnInit } from '@angular/core';
import { TranslatedProduct } from '../model/translated-product';
import { MatSliderChange } from '@angular/material/slider';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../data.service';
import { ImageHelper } from '../image.helper';

@Component({
  selector: 'app-image-series',
  templateUrl: './image-series.component.html',
})

export class ImageSeriesComponent implements OnInit {
  @Input() product: TranslatedProduct | null;
  constructor(@Inject(MAT_DIALOG_DATA) public data: TranslatedProduct, private dataService: DataService, private imageHelper: ImageHelper) { }
  autoTicks = false;
  disabled = false;
  max = 0;
  min = 0;
  showTicks = false;
  step = 1;
  thumbLabel = false;
  value = 0;
  selectedTimeStamp = "";
  images: string[];
  imageContents: any;
  selectedImageContent: any;
  timeStamps: string[];
  productId: number;
  play: number;
  speed: number;

  ngOnChanges() {
    //Log product opened here since it is special for 200-products
    this.dataService.productClicked(this.data.id).subscribe(result => {
    });


  }
  ngAfterViewInit() {
    this.setSelectedImageContent(0);
  }
  ngOnInit() {
    this.productId = this.data.id;
    this.images = this.data.files;
    this.imageContents = new Array(this.data.files.length).fill(null);
    this.timeStamps = this.data.files;
    this.max = (this.images.length - 1);
    this.value = 0;
    this.play = 0;
    this.speed = 500;
  }
  setSelectedImageContent(index: number) {
    if (!this.imageContents[index]) {
      this.dataService.getImage(this.timeStamps[index], this.productId)
        .subscribe(data => {
          this.imageContents[index] = this.imageHelper.getImageData(this.product?.files[0], data);
          this.selectedImageContent = this.imageContents[index];
        });
    } else {
      this.selectedImageContent = this.imageContents[index];
    }
  }
  onMouseEnter(index: number) {
    this.value = index;
    this.selectedTimeStamp = this.timeStamps[index];
    this.setSelectedImageContent(index);
  }
  decrementCounter() {
    this.value = this.value ? (this.value - 1) : 1;
    this.selectedTimeStamp = this.timeStamps[this.value];
    this.setSelectedImageContent(this.value);
  }

  incrementCounter() {
    this.value = this.value ? (this.value + 1) : 1;
    this.selectedTimeStamp = this.timeStamps[this.value];
    this.setSelectedImageContent(this.value);
  }
  updateLabel(event: MatSliderChange) {
    if (event.value) {
      this.value = event.value;
      this.selectedTimeStamp = this.timeStamps[event.value];
      this.setSelectedImageContent(event.value);
    }
  }

  async start() {
    var img = 0;
    this.value = -1;
    this.play = 1
    while (img <= this.max + 1 && this.play === 1) {
      this.incrementCounter();

      await this.sleep(this.speed)
      if (this.value >= this.max) { this.value = -1; img = 0; }
      img++
    }
  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  stop() {
    this.play = 0;
  }

  setSpeed(speed: any) {
    this.speed = speed as number;
  }

}
