<div *ngIf="selectedItems" class="table-product">
  <div>
    <span class="info-block">
      <b>Position: </b>{{lat | latitudeDirection}} {{lon |longitudeDirection}}, <b>Z0=</b>{{height}}<b> m</b>
    </span>
  </div>
  <div>
    <select (change)="newTimeSelected($event)">
      <option *ngFor="let timeSlot of timeSlots; let i = index" [value]="i">{{timeSlot}}</option>
    </select>
  </div>
  <table>
    <tbody>
      <tr>
        <th *ngFor="let field of selectedItems[0] | keyvalue">
          <div *ngIf="field.key != 'NoValuesFound'">
            {{ 'product.tableColumns.' + field.key | transloco }}
          </div>
        </th>
      </tr>
      <tr *ngFor="let item of selectedItems">
        <td *ngFor="let field of item | keyvalue">
          <div *ngIf="field.key != 'NoValuesFound'">
            <div *ngIf="item.NoValuesFound == false">
              {{field.value}}
            </div>
            <div *ngIf="item.NoValuesFound == true">
              <div *ngIf="field.key === 'fjsHP1height' || field.key === 'standardHp1RowNumber' ||
                   field.key === 'standardHp2heightInMeters' || field.key === 'uavHp1HeightInFeet'">
                {{field.value}}
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
