import { Component } from '@angular/core';
import { Observable } from "rxjs";
import { DataStore } from '../data.store';
import { LoggingService } from '../logging/logging.service';
import { TranslatedProduct } from '../model/translated-product';

@Component({
  selector: 'space',
  templateUrl: './space.component.html',
})
export class SpaceComponent {
  products: Observable<Array<TranslatedProduct>>;
  spaceHeader: string;
  spaceText: string;
  
  constructor(private dataStore: DataStore, private loggingService: LoggingService) {
    this.products = this.dataStore.spaceProducts;

    this.dataStore.spacePageHeader.subscribe(res => {
      this.spaceHeader = res;
    }, error => console.error(error));

    this.dataStore.spacePageText.subscribe(res => {
      this.spaceText = res;
    }, error => console.error(error));
  }
}
