import { Product } from "./product";

export class TranslatedProduct {
  id: number;
  name: string;
  description: string;
  place: string;
  isSigned: boolean;
  updateFrequencyInHours: number | null;
  arenaId: number;
  arena: string;
  operation: string;
  validFrom: string;
  validTo: string;
  daysToAddToReceivedDate: number | null;
  durationInHours: number | null;
  createdInBackend: string;
  productTypeId: number;
  isFavourite: boolean;
  files: string[];
  isLowBandwidthProduct: boolean;
  metadata: string;
  isUpdated: boolean;
  isActive: boolean;
  latestUpdated: string;
  latestUpdatedTicks: number;
  latestUpdateWithTime: string;
  tags: string[];

  constructor(product: Product, activeLanguage: string) {
    this.id = product.id;
    this.name = (activeLanguage === 'sv' ? product.name.sv : product.name.en);
    this.description = (activeLanguage === 'sv' ? product.description.sv : product.description.en);
    this.place = (activeLanguage === 'sv' ? product.place.sv : product.place.en);
    this.isSigned = product.isSigned;
    this.updateFrequencyInHours = product.updateFrequencyInHours;
    this.arenaId = product.arenaId;
    this.arena = this.getArena(product, activeLanguage);
    this.operation = product.operation;
    let fromDate = new Date(product.validFrom);
    this.validFrom = fromDate.toLocaleString('sv-SE', { hour12: false });
    let toDate = new Date(product.validTo);
    this.validTo = toDate.toLocaleString('sv-SE', { hour12: false });
    this.daysToAddToReceivedDate = product.daysToAddToReceivedDate;
    this.durationInHours = product.durationInHours;
    let createdDate = new Date(product.createdInBackend);
    this.createdInBackend = createdDate.toLocaleString('sv-SE', { hour12: false });
    this.productTypeId = product.productTypeId;
    this.isFavourite = product.isFavourite;
    this.isLowBandwidthProduct = product.isLowBandwidthProduct;
    this.files = product.files;
    this.metadata = this.getTagsString(product, activeLanguage);
    this.isUpdated = product.isUpdated;
    this.isActive = product.isActive;
    this.latestUpdated = product.latestUpdated;
    this.latestUpdatedTicks = product.latestUpdatedTicks;
    this.latestUpdateWithTime = product.latestUpdateWithTime;
    this.tags = this.getTagsArray();
  }

  getArena(product: Product, activeLanguage: string) {
    if (activeLanguage === 'sv') {
      return ArenaSv[product.arenaId];
    }

    return ArenaEn[product.arenaId];
  }

  getTagsString(product: Product, activeLanguage: string) {
    let tagsString = "";

    for (let tag of product.metadata) {
      if (activeLanguage === 'sv') {
        tagsString = tagsString + tag.sv + ";";
      }
      else {
        tagsString = tagsString + tag.en + ";";
      }
    }

    return tagsString.trim().replace(/;$/,''); //Removes ending semicolon
  }

  getTagsArray() {
    return this.metadata.split(';');
  }
}



enum ArenaSv {
  Land = 1,
  Luft = 2,
  Sjö = 3,
  Rymd = 4,
  Gemensamt = 5
}

enum ArenaEn {
  Land = 1,
  Air = 2,
  Sea = 3,
  Space = 4,
  Joint = 5
}
