import { Component, Input, OnChanges } from '@angular/core';
import { TranslatedProduct } from '../model/translated-product';
import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import Exporting from 'highcharts/modules/exporting';
import Heatmap from 'highcharts/modules/heatmap';
import { DataService } from '../data.service';
Exporting(Highcharts);
Heatmap(Highcharts);

@Component({
  selector: 'app-heat-map',
  templateUrl: './heat-map.component.html',
  standalone: true,
  imports: [HighchartsChartModule]
})

export class HeatMap implements OnChanges {

  constructor(private dataService: DataService) {
  }
  @Input() reRender: boolean;
  @Input() product: TranslatedProduct;
  public highcharts = Highcharts;
  updating = false;
  chart1Data: any;
  highchart1: Highcharts.Chart | undefined;

  ngOnChanges() {
    this.dataService.getJsonData('table_data.json', this.product.id)
      .subscribe(data => {
        this.chart1Data = data;
        this.highchart1 = this.highcharts.chart('highchart1Container', this.chart1Data);
      });
  }
}
